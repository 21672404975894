import React from 'react';
import { useSelector } from 'react-redux';

import styles from './DefDeskPage.module.scss';
import ProtectedRoute from '@shared/HOC/ProtectedRoute';

import Header from '@components/Header/Header';
import { NavSideBar } from '@components/NavSideBar/NavSideBar';
// import Preloader from '@components/UI/Preloader/Preloader';
import DefComp from '@components/DefComp/DefComp';
import SubHeader from '@components/SubHeader/SubHeader';
import { Tasks } from '@components/Tasks/Tasks';
import Preloader from '@components/UI/Preloader/Preloader';
import { RootState } from '@redux/store';
// import { Applications } from '@components/Applications/Applications/Applications';
// import { BeforeInstallPromptEvent } from '@shared/types';

const DefDeskPage = () => {
  const { isTasksLoading } = useSelector((state: RootState) => state.tasks);

  return (
    <main className={styles.page}>
      <NavSideBar />
      <Header deskPageName="Техподдержка" />
      <div>
        <SubHeader pageName={'Администрирование'} />

        {isTasksLoading ? (
          <Preloader />
        ) : (
          <div className={styles.body}>
            <Tasks />
            <div className={styles.reports}>
              <DefComp />
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

export default ProtectedRoute(DefDeskPage);
