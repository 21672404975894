import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {  TaskStatusEnum } from '@shared/enums';
import { IFilter, UidNameType } from '@shared/types';

interface filterState extends IFilter {
  isOpen: boolean;
  isActive: boolean;
}

const initialState: filterState = {
  isOpen: false,
  isActive: false,
  projectFilter: { Name: '', UID: '' },
  initiatorFilter: { Name: '', UID: '' },
  executorFilter: { Name: '', UID: '' },
  dateRangeFilter: [0, 0],
  statusFilter: [],
};

const handleIsActive = (state: filterState): boolean =>
  !!state.projectFilter.UID ||
  !!state.initiatorFilter.UID ||
  !!state.executorFilter.UID ||
  !!state.dateRangeFilter[0] ||
  !!state.dateRangeFilter[1] ||
  !!state.statusFilter.length;

const FilterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setSavedFilterState(state) {
      const { TasksFilters } = JSON.parse(
        localStorage.getItem('TasksFilters') || '{"TasksFilters": ""}'
      );
      return {
        ...state,
        ...(TasksFilters || initialState),
        isActive: TasksFilters && handleIsActive(TasksFilters),
      };
    },
    setFilterModalState(state, action: PayloadAction<boolean>) {
      return { ...state, isOpen: action.payload };
    },
    setFilterStatus(state, action: PayloadAction<TaskStatusEnum>) {
      const isStatusFilterActive = state.statusFilter.includes(action.payload);
      const newArr = isStatusFilterActive
        ? state.statusFilter.filter((item) => item !== action.payload)
        : [...state.statusFilter, action.payload];
      const newState = { ...state, statusFilter: newArr };
      localStorage.setItem('TasksFilters', JSON.stringify({ TasksFilters: newState }));
      return { ...newState, isActive: handleIsActive(newState) };
    },
    setFilterFields(state, action: PayloadAction<IFilter>) {
      const newState = { ...state, ...action.payload };
      localStorage.setItem('TasksFilters', JSON.stringify({ TasksFilters: newState }));
      return { ...state, ...action.payload, isActive: handleIsActive(newState) };
    },
    resetFiltersFields() {
      localStorage.setItem('TasksFilters', JSON.stringify({ TasksFilters: initialState }));
      return { ...initialState };
    },
    resetFilerStatus(state) {
      const newState = { ...state, statusFilter: [] };
      localStorage.setItem('TasksFilters', JSON.stringify({ TasksFilters: newState }));
      return { ...newState, isActive: handleIsActive(newState) };
    },
    setFilterByMyTasks(state, action: PayloadAction<UidNameType>) {
      const newState = { ...state, initiatorFilter: action.payload };
      localStorage.setItem('TasksFilters', JSON.stringify({ TasksFilters: newState }));
      return { ...newState, isActive: handleIsActive(newState) };
    },
  },
});

export const {
  setSavedFilterState,
  setFilterModalState,
  setFilterStatus,
  setFilterFields,
  resetFiltersFields,
  resetFilerStatus,
  setFilterByMyTasks,
} = FilterSlice.actions;

export default FilterSlice.reducer;
