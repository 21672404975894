import React from 'react';

import styles from './DefMobilePage.module.scss';
import ProtectedRoute from '@shared/HOC/ProtectedRoute';
// import { BeforeInstallPromptEvent } from '@shared/types';

import Header from '@components/Header/Header';
// import Preloader from '@components/UI/Preloader/Preloader';
import { Footer } from '@components/Footer/Footer';
import DefComp from '@components/DefComp/DefComp';
import { RootState } from '@redux/store';
import { useSelector } from 'react-redux';
import Preloader from '@components/UI/Preloader/Preloader';

const DefMobilePage = () => {
  const { isTasksLoading } = useSelector((state: RootState) => state.tasks);

  return (
    <main className={styles.page}>
      <Header />
      {isTasksLoading ? <Preloader /> : <DefComp />}
      <Footer />
    </main>
  );
};

export default ProtectedRoute(DefMobilePage);
