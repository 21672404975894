import React, { useCallback, useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import { useParams } from 'react-router-dom';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';

import styles from './Tasks.module.scss';
import { TasksDispatch, RootState } from '@redux/store';
import { TaskListType } from '@shared/types';
import { handleFilterByStatusAndDataRange } from '@shared/hooks';
import { AuthContext } from '@contexts/CurrUserContext';
import { ColumnNameEnum, TaskStatusEnum } from '@shared/enums';
import { fetchDataPostSimple } from 'api';
import {
  setFilterModalState,
  setFilterByMyTasks,
  resetFilerStatus,
  setFilterStatus,
} from '@redux/slices/filterSlices';

// import FilterInput from '@components/UI/FilterInput';
import Preloader from '@components/UI/Preloader/Preloader';
import { Card } from '@components/UI/Card';
import { ModalTasksFilter } from '@components/Modal/ModalTasksFilter';
import { BoardListCheckBox, FilterButton } from '@components/UI/ArrHeadingButtons';
import { ExtendButton } from '@components/UI/ExtendButton';

export const Tasks = () => {
  const { task } = useParams();
  const {
    isOpen,
    isActive,
    statusFilter,
    dateRangeFilter,
    initiatorFilter,
    executorFilter,
    projectFilter,
  } = useSelector((state: RootState) => state.filter);
  const [currTasks, setCurrTasks] = useState<TaskListType[]>([]);
  const [boardChecked, setBoardChecked] = useState(false);
  const [currColumn, setCurrColumn] = useState<ColumnNameEnum>(ColumnNameEnum['Waiting']);
  const [isLoading, setIsLoading] = useState(false);
  const [currPage, setCurrPage] = useState(1);
  const [isEnd, setIsEnd] = useState(false);
  const dispatch = useDispatch<TasksDispatch>();
  const { userInfo } = useContext(AuthContext);

  // обновление списка заявок при применении фильтра (всех кроме открытия и закрытия модального окна)
  useEffect(() => {
    dispatch(setFilterModalState(false));
    setIsLoading(true);
    const reqBody = { Part: 1 };
    projectFilter.UID && Object.assign(reqBody, { Project: projectFilter.UID });
    initiatorFilter.UID && Object.assign(reqBody, { Initiator: initiatorFilter.UID });
    executorFilter.UID && Object.assign(reqBody, { Executor: executorFilter.UID });
    fetchDataPostSimple('tasks/list', reqBody)
      .then((res) =>
        setCurrTasks(handleFilterByStatusAndDataRange(dateRangeFilter, statusFilter, res))
      )
      .catch((err) => console.error(err))
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilter, dateRangeFilter, initiatorFilter, executorFilter]);

  useEffect(() => {
    if (boardChecked) {
      setIsLoading(true);
      const reqBody = { Desk: 'Waiting' };
      projectFilter.UID && Object.assign(reqBody, { Project: projectFilter.UID });
      initiatorFilter.UID && Object.assign(reqBody, { Initiator: initiatorFilter.UID });
      executorFilter.UID && Object.assign(reqBody, { Executor: executorFilter.UID });
      fetchDataPostSimple('tasks/list', reqBody)
        .then((res) =>
          setCurrTasks(handleFilterByStatusAndDataRange(dateRangeFilter, statusFilter, res))
        )
        .catch((err) => console.error(err))
        .finally(() => setIsLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boardChecked]);

  // const handleSortTaskByDesc = (v: string) => {};
  // v
  // ? setCurrTasks(
  //     tasksArr?.filter(
  //       (task) =>
  //         task.Subject?.toLowerCase().includes(v.toLowerCase()) || task.Number.indexOf(v) !== -1
  //     )
  //   )
  // : setCurrTasks(tasksArr);

  const handleOnClickByFilters = (v: 'My' | 'Appointed' | 'Done') => {
    dispatch(resetFilerStatus());
    switch (v) {
      case 'My':
        dispatch(setFilterByMyTasks({ UID: userInfo.UID, Name: userInfo.Name }));
        return;
      case 'Appointed':
        dispatch(setFilterByMyTasks({ UID: '', Name: '' }));
        dispatch(setFilterStatus(TaskStatusEnum['Appointed']));
        return;
      default:
        dispatch(setFilterByMyTasks({ UID: '', Name: '' }));
        dispatch(setFilterStatus(TaskStatusEnum['Done']));
        return;
    }
  };

  const handleClickByColumn = (v: ColumnNameEnum) => {
    const currValue = Object.values(ColumnNameEnum).indexOf(v);
    setCurrColumn(Object.values(ColumnNameEnum)[currValue]);
    setIsLoading(true);
    const reqBody = { Desk: Object.keys(ColumnNameEnum)[currValue] };
    projectFilter.UID && Object.assign(reqBody, { Project: projectFilter.UID });
    initiatorFilter.UID && Object.assign(reqBody, { Initiator: initiatorFilter.UID });
    executorFilter.UID && Object.assign(reqBody, { Executor: executorFilter.UID });
    fetchDataPostSimple('tasks/list', reqBody)
      .then((res) =>
        setCurrTasks(handleFilterByStatusAndDataRange(dateRangeFilter, statusFilter, res))
      )
      .catch((err) => console.error(err))
      .finally(() => setIsLoading(false));
  };

  const fastFilters = useCallback(
    () => (
      <div className={styles.tagContainer}>
        <ScrollContainer className={styles.tags} vertical={false}>
          <button
            className={cn(styles.tag, initiatorFilter.UID === userInfo.UID && styles.tag_active)}
            onClick={() => handleOnClickByFilters('My')}
          >
            Мои задачи
          </button>
          <button
            className={cn(
              styles.tag,
              statusFilter.includes(TaskStatusEnum['Appointed']) && styles.tag_active
            )}
            onClick={() => handleOnClickByFilters('Appointed')}
          >
            Новая
          </button>
          <button
            className={cn(
              styles.tag,
              statusFilter.includes(TaskStatusEnum['Done']) && styles.tag_active
            )}
            onClick={() => handleOnClickByFilters('Done')}
          >
            Выполнена
          </button>
        </ScrollContainer>
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initiatorFilter.UID, statusFilter, userInfo.UID]
  );

  const deskFilters = useCallback(
    () => (
      <div className={styles.columns}>
        <div
          className={cn(
            styles.column,
            currColumn === ColumnNameEnum['Waiting'] && styles.column_await
          )}
          onClick={() => handleClickByColumn(ColumnNameEnum['Waiting'])}
        >
          <p className={styles.text}>Ожидание</p>
        </div>
        <div
          className={cn(styles.column, currColumn === ColumnNameEnum['ToDo'] && styles.column_todo)}
          onClick={() => handleClickByColumn(ColumnNameEnum['ToDo'])}
        >
          <p className={styles.text}>Делать</p>
        </div>
        <div
          className={cn(
            styles.column,
            currColumn === ColumnNameEnum['InProgress'] && styles.column_inprog
          )}
          onClick={() => handleClickByColumn(ColumnNameEnum['InProgress'])}
        >
          <p className={styles.text}>В работе</p>
        </div>
        <div
          className={cn(
            styles.column,
            currColumn === ColumnNameEnum['Done'] && styles.column_complete
          )}
          onClick={() => handleClickByColumn(ColumnNameEnum['Done'])}
        >
          <p className={styles.text}>Выполнена</p>
        </div>
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currColumn]
  );

  const fetchMoreTasks = () => {
    const reqBody = { Part: currPage + 1 };
    setCurrPage((prev) => prev + 1);
    if (boardChecked) {
      const currValue = Object.values(ColumnNameEnum).indexOf(currColumn);
      Object.assign(reqBody, { Desk: Object.keys(ColumnNameEnum)[currValue] });
    }
    projectFilter.UID && Object.assign(reqBody, { Project: projectFilter.UID });
    initiatorFilter.UID && Object.assign(reqBody, { Initiator: initiatorFilter.UID });
    executorFilter.UID && Object.assign(reqBody, { Executor: executorFilter.UID });
    fetchDataPostSimple('tasks/list', reqBody)
      .then((res) => {
        res.length === 0 && setIsEnd(true);
        setCurrTasks([...currTasks, ...res]);
      })
      .catch((err) => console.error(err));
  };

  return (
    <div className={cn(styles.info, isOpen && styles.info_blocked)} id="scrollableDiv">
      {/* <FilterInput onChange={handleSortTaskByDesc} /> */}
      <div className={styles.heading}>
        <FilterButton onClick={() => dispatch(setFilterModalState(true))} isActive={isActive} />
        <BoardListCheckBox onClick={() => setBoardChecked(!boardChecked)} isActive={boardChecked} />
      </div>

      {!boardChecked ? fastFilters() : deskFilters()}

      <>
        {currTasks.length === 0 ? (
          isLoading ? (
            <div style={{ margin: '20px auto 0' }}>
              <Preloader />
            </div>
          ) : (
            <p className={styles.text}>Заявок нет</p>
          )
        ) : (
          <InfiniteScroll
            dataLength={currTasks.length}
            next={fetchMoreTasks}
            hasMore={!isEnd}
            loader={
              <div className={styles.preloader}>
                <Preloader />
              </div>
            }
            className={styles.cards}
            scrollThreshold="20px"
            scrollableTarget="scrollableDiv"
          >
            {currTasks.map((item) => (
              <Card
                key={item.UID}
                cardInfo={item}
                isActive={item.UID === task}
                // onClickHandler={() => dispatch(removeNotification(item.UID))}
                onClickHandler={() => console.log(item.UID)}
              />
            ))}
          </InfiniteScroll>
        )}
      </>

      {isOpen && <ModalTasksFilter />}
    </div>
  );
};

export const TasksModal = ({
  isTasksListOpen,
  setTasksListOpen,
}: {
  isTasksListOpen: boolean;
  setTasksListOpen: (v: boolean) => void;
}) => {
  return (
    <div className={styles.appContainer}>
      <ExtendButton
        onClick={() => setTasksListOpen(!isTasksListOpen)}
        arrowDirection={isTasksListOpen ? 'left' : 'right'}
        backColor="#fff"
        className={cn(styles.extendButton, isTasksListOpen && styles.extendButton_active)}
      />
      <div className={cn(styles.applications, isTasksListOpen && styles.applications_open)}>
        <Tasks />
      </div>
    </div>
  );
};
