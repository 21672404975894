import React, { useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './Task.module.scss';
import { TaskStatusEnum } from '@shared/enums';
import { RootState } from '@redux/store';
import { CheckListItemType, TaskDescType } from '@shared/types';

import StatusSelect from '@components/UI/StatusSelect';
import { SimpleTextArea } from '@components/UI/SimpleTextArea';
import SearchInput from '@components/UI/SearchInput';
import { CheckItem } from '@components/UI/CheckItem';
import CustomEditor from '@components/UI/CustomEditor';
import { handleChangeCheckList } from '@shared/hooks';

interface IProps {
  taskDesc: TaskDescType;
  setTaskDesk: (v: TaskDescType) => void;
}

const Task: React.FC<IProps> = ({ taskDesc, setTaskDesk }) => {
  const [isMobile, setIsMobile] = useState(document.documentElement.scrollWidth < 1000);
  const { usersArr } = useSelector((state: RootState) => state.tasks);

  useLayoutEffect(() => {
    const handlePageWidth = () => {
      setIsMobile(document.documentElement.scrollWidth < 990);
    };
    handlePageWidth();
    window.addEventListener('resize', handlePageWidth);
    return () => window.removeEventListener('resize', handlePageWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeDesk = (v: TaskDescType) => {
    if (taskDesc.ReadOnly) return;
    setTaskDesk({ ...v, isChanged: true });
  };

  return (
    <div className={styles.task}>
      <label className={styles.label}>
        <div className={styles.selectContainer}>
          <p className={styles.orgName}># {taskDesc.Number}</p>
          <div className={styles.dateItem}>
            <span className={styles.dateLogo} />
            <p className={styles.dateText}>
              {taskDesc.Date.toString().split('-').join('.').split('T').join(' - ')}
            </p>
          </div>
        </div>
        <StatusSelect
          currOption={taskDesc.Status as TaskStatusEnum}
          options={taskDesc.ValidStatuses.map((item: string) => item) as TaskStatusEnum[]}
          setCurrOption={(e) =>
            setTaskDesk({ ...taskDesc, Status: e as TaskStatusEnum, isChanged: true })
          }
          disabled={taskDesc.ValidStatuses.length === 0}
        />
      </label>
      <div style={{ margin: '0 20px' }}>
        <SimpleTextArea
          placeholder={'Тема задачи'}
          name={'taskName'}
          value={taskDesc.Subject}
          handleChange={(e) => e.preventDefault()}
          styling={{
            margin: '36px 0 50px',
            padding: '15px 50px 0 0',
            headColor: '#FFFFFF',
            textColor: '#FFF',
          }}
          disabled={true}
        />
      </div>
      {/* сюда подставить выбор проекта */}
      <div className={styles.additional}>
        {' '}
        <SearchInput
          currOption={taskDesc.Executor}
          options={usersArr}
          setCurrOption={(e) =>
            handleChangeDesk({ ...taskDesc, Executor: e, Status: TaskStatusEnum['InProgress'] })
          }
          styling={{
            margin: '0 0 0',
            padding: '0 0 0',
            headColor: isMobile ? '#BFC8E8' : '#FFFFFF',
            textColor: isMobile ? '#363942' : '#FFFFFF',
          }}
          heading={'Исполнитель'}
          dialogText={'Выбор исполнителя'}
          disabled={taskDesc.ReadOnly}
          name="Executor"
        />
        <SearchInput
          currOption={taskDesc.Initiator}
          options={usersArr}
          setCurrOption={(e) => handleChangeDesk({ ...taskDesc, Initiator: e })}
          styling={{
            margin: '0 0 0',
            padding: '0 0 0',
            headColor: isMobile ? '#BFC8E8' : '#FFFFFF',
            textColor: isMobile ? '#363942' : '#FFFFFF',
          }}
          heading={'Инициатор'}
          dialogText={'Выбор инициатора'}
          disabled={taskDesc.ReadOnly}
          name="Initiator"
        />
        {/* <DescTextArea
          value={taskDesc.Note}
          handleChange={(e) => handleChangeDesk({ ...taskDesc, Note: e.target.value })}
          name={'Описание'}
          styling={{
            padding: ' 0 0 0',
            margin: '19px 0 0',
            headColor: isMobile ? '#BFC8E8' : '#FFFFFF',
            textColor: isMobile ? '#BFC8E8' : '#FFFFFF',
          }}
          handleUploadFile={(e) => console.log(1)}
          isLoading={false}
          // handleUploadFile={handleSetNewFileComment}
          // isLoading={isLoading}
        /> */}
        <CustomEditor
          value={taskDesc.Note}
          handleChange={(e) => {}}
          name={'Описание'}
          styling={{
            padding: ' 0 0 10px',
            margin: '15px 0 0',
            headColor: isMobile ? '#BFC8E8' : '#FFFFFF',
            textColor: isMobile ? '#363942' : '#FFFFFF',
          }}
          disabled
        />
        <div className={styles.subtasksLabel}>
          <p className={styles.text}>Добавить чек-лист</p>
          <div className={styles.subtasksItems}>
            {!!taskDesc.CheckList.length &&
              taskDesc.CheckList.map((item, index) => (
                <CheckItem
                  checkItemInfo={item}
                  onClick={() =>
                    handleChangeDesk({
                      ...taskDesc,
                      CheckList: handleChangeCheckList(
                        taskDesc.CheckList,
                        'onClick',
                        '',
                        index
                      ) as CheckListItemType[],
                    })
                  }
                  onChange={(e) =>
                    setTaskDesk({
                      ...taskDesc,
                      CheckList: handleChangeCheckList(
                        taskDesc.CheckList,
                        'onChange',
                        e.target.value,
                        index
                      ) as CheckListItemType[],
                    })
                  }
                  canMoveUp={index !== 0}
                  handleMoveUp={() =>
                    setTaskDesk({
                      ...taskDesc,
                      CheckList: handleChangeCheckList(
                        taskDesc.CheckList,
                        'up',
                        '',
                        index
                      ) as CheckListItemType[],
                    })
                  }
                  canMoveDown={taskDesc.CheckList.length - 1 !== index}
                  handleMoveDown={() =>
                    setTaskDesk({
                      ...taskDesc,
                      CheckList: handleChangeCheckList(
                        taskDesc.CheckList,
                        'down',
                        '',
                        index
                      ) as CheckListItemType[],
                    })
                  }
                  handleDelete={() =>
                    setTaskDesk({
                      ...taskDesc,
                      CheckList: handleChangeCheckList(
                        taskDesc.CheckList,
                        'delete',
                        '',
                        index
                      ) as CheckListItemType[],
                    })
                  }
                  key={item.CheckNumber}
                  canConvertToTask={false}
                  createTask={() => console.log(1)}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Task;
