import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import { ru } from 'date-fns/locale';

import { TasksDispatch, RootState } from '@redux/store';
import styles from './ModalTasksFilter.module.scss';
import { TaskStatusEnum } from '@shared/enums';
import { UidNameType } from '@shared/types';
import 'react-datepicker/dist/react-datepicker.css';
import {
  setFilterFields,
  setFilterModalState,
  resetFiltersFields,
} from '@redux/slices/filterSlices';

import SimpleSearchInput from '@components/UI/SimpleSearchInput';
import SearchInput from '@components/UI/SearchInput';

export const ModalTasksFilter = () => {
  const { usersArr, projectArr } = useSelector((state: RootState) => state.tasks);
  const { isOpen, projectFilter, initiatorFilter, executorFilter, dateRangeFilter, statusFilter } =
    useSelector((state: RootState) => state.filter);

  const [currProject, setCurrProject] = useState<UidNameType>(projectFilter);
  const [currInitiator, setCurrInitiator] = useState<UidNameType>(initiatorFilter);
  const [currExecutor, setCurrExecutor] = useState<UidNameType>(executorFilter);
  const [currStatus, setCurrStatus] = useState<TaskStatusEnum[]>(statusFilter);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    dateRangeFilter[0] ? new Date(dateRangeFilter[0]) : null,
    dateRangeFilter[1] ? new Date(dateRangeFilter[1]) : null,
  ]);
  const [startDate, endDate] = dateRange;
  registerLocale('ru', ru);
  const dispatch = useDispatch<TasksDispatch>();
  const modalRef = useRef<null | HTMLDivElement>(null);

  // useEffect(() => {
  //   !!currServ.UID &&
  //     fetchDataPost('Categories', currServ.UID)
  //       .then((res) => {
  //         if (res.length === 1) setCurrCategory(res[0]);
  //         setCategoriesArr(res);
  //       })
  //       .then(() => fetchDataPost('users', currServ.UID))
  //       .then((users) => {
  //         let exeArr: UserType[] = [];
  //         let cooArr: UserType[] = [];
  //         users.map((user: UserType) => {
  //           user.IsCoordinator && cooArr.push(user);
  //           return user.IsExecutor && exeArr.push(user);
  //         });
  //         setExecutorsArr(exeArr);
  //         setCoordinatorsArr(cooArr);
  //       })
  //       .catch((err) => console.error(err));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currServ.UID]);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        window.addEventListener('click', onClick);
      }, 100);
    }
    return () => window.removeEventListener('click', onClick);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const onClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
      dispatch(setFilterModalState(false));
    }
  };

  const handleSubmitForm = (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();
    const startDate = dateRange[0] ? dateRange[0][Symbol.toPrimitive]('number') : 0;
    const finDate = dateRange[1] ? dateRange[1][Symbol.toPrimitive]('number') : 0;
    dispatch(
      setFilterFields({
        projectFilter: currProject,
        initiatorFilter: currInitiator,
        executorFilter: currExecutor,
        dateRangeFilter: [startDate, finDate],
        statusFilter: currStatus,
      })
    );
    console.log(123);
    dispatch(setFilterModalState(false));
  };

  const handleResetFilter = () => {
    dispatch(resetFiltersFields());
    dispatch(setFilterModalState(false));
  };

  const handleChangeStatusCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value as TaskStatusEnum;
    if (currStatus.includes(value)) {
      setCurrStatus(currStatus.filter((item) => item !== value));
    } else {
      setCurrStatus((prev) => [...prev, value]);
    }
  };

  const handleSetDateRange = (
    date: [Date | null, Date | null],
    event: React.SyntheticEvent<any, Event> | undefined
  ) => {
    event?.stopPropagation();
    setDateRange(date);
  };

  return (
    <section className={styles.modal} ref={modalRef}>
      <div className={styles.heading}>
        <span className={styles.formBackground}></span>

        <SimpleSearchInput
          currOption={currProject.Name}
          options={projectArr}
          setCurrOption={(v) => setCurrProject(v)}
          styling={{
            margin: '10px 0 0',
            headColor: '#FFF',
            textColor: '#FFF',
            dialogColor: '#363942',
          }}
          heading={'Проект'}
          dialogText={'Выберите проект'}
          name="Service"
          disabled={false}
        />
        {/* <p style={{ margin: '20px 0' }}>{.Name}</p> */}

        {/* <SimpleSearchInput
          currOption={currCategory.Name}
          options={categoriesArr}
          setCurrOption={(v) => setCurrCategory(v)}
          styling={{
            margin: '0px 0 0',
            headColor: '#FFF',
            textColor: '#FFF',
            dialogColor: '#363942',
          }}
          heading={CategoryName}
          dialogText={CategoryShowName}
          disabled={!categoriesArr.length}
          name="Category"
        /> */}
      </div>
      <div className={styles.body}>
        <div className={styles.addition}>
          <div className={styles.checkItem}>
            <input
              type="checkbox"
              id={'all'}
              onChange={() => dispatch(resetFiltersFields())}
              checked={
                !currStatus.length &&
                !currInitiator?.Name &&
                !currExecutor?.Name &&
                !dateRange[0] &&
                !dateRange[1]
              }
              className={styles.checkbox}
            />
            <label htmlFor={'all'} className={styles.checkText}>
              Все задачи
            </label>
          </div>
        </div>

        <div className={styles.addition}>
          <p className={styles.dateText}>Статус заявки</p>
          <div>
            {Object.values(TaskStatusEnum).map((item) => (
              <div key={item} className={styles.checkItem}>
                <input
                  type="checkbox"
                  id={item}
                  value={item}
                  onChange={handleChangeStatusCheckbox}
                  checked={currStatus.includes(item)}
                  className={styles.checkbox}
                />
                <label htmlFor={item} className={styles.checkText}>
                  {item}
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.dateItem}>
          <p className={styles.dateText}>Период</p>
          <DatePicker
            // showIcon
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={handleSetDateRange}
            dateFormat="yyyy-MM-dd"
            locale="ru"
            className={styles.datepicker}
            wrapperClassName={styles.datepickerWrapper}
          />
        </div>
        <div className={styles.checkSelect}>
          <label className={styles.checkLabel}>
            <input
              type="checkbox"
              checked={currInitiator.Name !== ''}
              className={styles.checkbox}
              onChange={() => setCurrInitiator({ Name: '', UID: '' })}
            />
            <p className={styles.checkText}>Задачи от</p>
          </label>
          <SearchInput
            currOption={currInitiator}
            options={usersArr}
            setCurrOption={setCurrInitiator}
            styling={{ textColor: '#363942' }}
            dialogText={'Выбор инициатора'}
            name="Initiator"
          />
        </div>

        <div className={styles.checkSelect}>
          <label className={styles.checkLabel}>
            <input
              type="checkbox"
              checked={currExecutor.Name !== ''}
              className={styles.checkbox}
              onChange={() => setCurrExecutor({ UID: '', Name: '' })}
            />
            <p className={styles.checkText}>Задачи кому</p>
          </label>

          <SearchInput
            currOption={currExecutor}
            options={usersArr}
            setCurrOption={setCurrExecutor}
            styling={{ textColor: '#363942' }}
            dialogText={'Выбор исполнителя'}
            name="Coordinator"
          />
        </div>

        <div className={styles.buttons}>
          <button className={styles.button_reset} onClick={() => handleResetFilter()}>
            Сбросить
          </button>
          <button className={styles.button_submit} onClick={(e) => handleSubmitForm(e)}>
            Показать
          </button>
        </div>
      </div>
    </section>
  );
};
