// import { NewTaskStatus, PriorityType, TaskStatus } from '@shared/enums';
import { NewTaskStatusEnum, TaskStatusEnum } from '@shared/enums';
import { NewTaskDescType, TaskDescType } from '@shared/types';

// import { AppDescType, NewAppType, NewInitiatorType, UserInfoType } from '@shared/types';
export const regExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const NameUID = { UID: '', Name: '' };

export const emptyPass = '{"pass": ""}';

export const ServiceName = 'Участок';
// export const ServiceName = 'Сервис';
export const ServiceShowName = 'Выбор участка';
// export const ServiceShowName = 'Выбор сервиса';
export const CategoryName = 'Сервис';
// export const CategoryName = 'Категория';
export const CategoryShowName = 'Выбор сервиса';
// export const CategoryShowName = 'Выбор категории';

export const NewTaskDeskDefault: NewTaskDescType = {
  Subject: '',
  Parent: NameUID,
  Initiator: NameUID,
  Address: '',
  Executor: NameUID,
  Coordinator: NameUID,
  Service: NameUID,
  Project: NameUID,
  Note: '',
  Status: NewTaskStatusEnum['Draft'],
  Category: NameUID,
  DeadLine: '',
  Request: NameUID,
  RequestUID: '',
  Priority: '',
  CheckList: [],
};

export const TaskDescDefault: TaskDescType = {
  UID: '',
  Number: '',
  Date: '',
  Status: TaskStatusEnum['Draft'],
  Initiator: NameUID,
  Executor: NameUID,
  Subject: '',
  Note: '',
  ReadOnly: true,
  ValidStatuses: [],
  isChanged: false,
  Parent: {
    UID: '',
    TaskNumber: '',
    TaskDate: '',
    TaskDeadLine: '',
    TaskSubject: '',
  },
  Deadline: '',
  Project: {
    UID: '',
    Name: '',
  },
  CheckList: [],
};

// export const UserDeskDefault: UserInfoType = {
//   UID: '',
//   FullName: '',
//   Name: '',
//   Organization: NameUID,
//   Department: NameUID,
//   Position: '',
//   Priority: PriorityType['medium'],
//   Address: '',
//   Phone: '',
//   Email: '',
//   Telegramm: '',
//   NotificationResolution: false,
//   Comment: '',
//   TakeApplications: false,
//   Service: NameUID,
//   Category: NameUID,
//   Avatar: '',
// };

// export const NewInitiatorDefault: NewInitiatorType = {
//   FullName: '',
//   Name: '',
//   Organization: NameUID,
//   Department: NameUID,
//   Position: '',
//   Address: '',
//   Email: '',
//   PriorityDefault: PriorityType['medium'],
//   NotificationResolution: true,
// };
