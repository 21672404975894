import { useState } from 'react';
import { useDispatch } from 'react-redux';
// import imageCompression from 'browser-image-compression';

import styles from './NewTaskDeskPage.module.scss';
import { NewTaskDescType } from '@shared/types';
// import { ModalType } from '@shared/enums';
// import { handleCreateNewApplication } from 'api';
import ProtectedRoute from '@shared/HOC/ProtectedRoute';
import { NewTaskDeskDefault } from '@shared/constants';
import { refreshTasks } from '@redux/slices/tasksSlice';
import { TasksDispatch } from '@redux/store';

import Header from '@components/Header/Header';
import { NavSideBar } from '@components/NavSideBar/NavSideBar';
import SubHeader from '@components/SubHeader/SubHeader';
// import { ApplicationsModal } from '@components/Applications/Applications/Applications';
import Preloader from '@components/UI/Preloader/Preloader';
import { SimpleBigButton } from '@components/UI/Button';
// import NewApplication from '@components/Applications/NewApplication/NewApplication';
// import ModalInfo from '@components/Modal/ModalInfo/ModalInfo';
// import { AuthContext } from '@contexts/CurrUserContext';
import { handleChangeCheckList } from '@shared/hooks';
// import FilePreviewContainer from '@components/UI/FilePreviewContainer';
import NewTask from '@components/Tasks/NewTask';
import { fetchDataPostSimple } from 'api';
import { TasksModal } from '@components/Tasks/Tasks';
import { SimpleTextArea } from '@components/UI/SimpleTextArea';
import CustomEditor from '@components/UI/CustomEditor';
import { CheckList } from '@components/UI/CheckList';
import { CheckItem } from '@components/UI/CheckItem';

const NewTaskDeskPage = () => {
  // const { userInfo } = useContext(AuthContext);
  const [newTaskDesc, setNewTaskDesc] = useState<NewTaskDescType>(NewTaskDeskDefault);
  const [isTasksListOpen, setTasksListOpen] = useState(false);
  // const [taskFiles, setTaskFiles] = useState<{ file: string; name: string; link: string }[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [openResultModal, setResultModal] = useState(false);
  // const [typeResultModal, setTypeResultModal] = useState<ModalType>('new' as ModalType);
  // const [newAppNumber, setNewAppNumber] = useState('');
  const dispatch = useDispatch<TasksDispatch>();

  // useEffect(() => {
  //   userInfo.IsSeniorExecutor &&
  //     setNewApp({
  //       ...newApp,
  //       Executor: { UID: userInfo.UID, Name: userInfo.Name || userInfo.FullName },
  //     });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [userInfo.UID]);

  const handleSubmitForm = () => {
    setIsLoading(true);
    const reqBody = {
      Subject: newTaskDesc.Subject,
      Initiator: newTaskDesc.Initiator.UID,
      Project: newTaskDesc.Project.UID,
      Note: newTaskDesc.Note,
      Status: newTaskDesc.Status,
    };
    !!newTaskDesc.Executor.UID && Object.assign(reqBody, { Executor: newTaskDesc.Executor.UID });
    !!newTaskDesc.Parent?.UID && Object.assign(reqBody, { Parent: newTaskDesc.Parent.UID });
    !!newTaskDesc.DeadLine && Object.assign(reqBody, { DeadLine: newTaskDesc.DeadLine });
    !!newTaskDesc.CheckList?.length && Object.assign(reqBody, { CheckList: newTaskDesc.CheckList });
    fetchDataPostSimple('tasks/add', reqBody)
      .then((res) => {
        // setResultModal(true);
        // setTypeResultModal(ModalType['newApp']);
        // setNewAppNumber(res.Number);
        setNewTaskDesc(NewTaskDeskDefault);
        dispatch(refreshTasks());
      })
      .catch((err) => {
        console.error(err);
        // setResultModal(true);
        // setTypeResultModal(ModalType['err']);
      })
      .finally(() => setIsLoading(false));
  };

  // const handleAddNewFile = (v: { file: string; name: string; link: string }) => {
  //   setTaskFiles([...taskFiles, v]);
  // };

  // const handleDeleteFile = (name: string) => {
  //   setTaskFiles(taskFiles.filter((item) => item.name !== name));
  // };

  // const handleSetNewFileComment = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   if (!e.target.files?.length) return;
  //   const currFile = e.target.files[0];
  //   e.target.value = '';
  //   if (!currFile || taskFiles.find((item) => item.name === currFile.name)) return;
  //   let fileItem = '';
  //   setIsLoading(true);
  //   imageCompression(currFile, {
  //     maxSizeMB: 1,
  //     maxWidthOrHeight: 1920,
  //   })
  //     .then((res) => getBase64(res))
  //     .then((result) => {
  //       if (currFile.type.split('/')[0] === 'image') {
  //         fileItem = `<img src="${result}" alt="${currFile.name}" />`;
  //         handleAddNewFile({
  //           file: fileItem,
  //           name: currFile.name,
  //           link: URL.createObjectURL(currFile),
  //         });
  //       }
  //     })
  //     .catch((err) => console.log(err))
  //     .finally(() => setIsLoading(false));
  // };

  return (
    <main className={styles.page}>
      <NavSideBar />
      <Header deskPageName="Техподдержка" />
      <div>
        <SubHeader pageName={'Создать заявку'} />
        <div className={styles.body}>
          <TasksModal isTasksListOpen={isTasksListOpen} setTasksListOpen={setTasksListOpen} />
          <div className={styles.additional}>
            <div className={styles.comments}>
              <SimpleTextArea
                placeholder={'Тема задачи'}
                name={'taskName'}
                value={newTaskDesc.Subject}
                handleChange={(e) => setNewTaskDesc({ ...newTaskDesc, Subject: e.target.value })}
                styling={{ padding: '15px 50px 0 0', headColor: '#BFC8E8', textColor: '#363942' }}
              />
              {/* <DescTextArea
                value={newTaskDesc.Note}
                handleChange={(e) => setNewTaskDesc({ ...newTaskDesc, Note: e.target.value })}
                name={'Описание'}
                styling={{ padding: ' 0 0 0', margin: '29px 0 0', textColor: '#BFC8E8' }}
                handleUploadFile={handleSetNewFileComment}
                isLoading={isLoading}
              /> */}

              <CustomEditor
                value={newTaskDesc.Note}
                handleChange={(e) => setNewTaskDesc({ ...newTaskDesc, Note: e.value })}
                name={'Добавить описание'}
                styling={{
                  padding: ' 0 0 10px',
                  margin: '15px 0 0',
                  headColor: '#BFC8E8',
                  textColor: '#363942',
                }}
              />
              <CheckList
                addNewCheckItem={() =>
                  setNewTaskDesc({
                    ...newTaskDesc,
                    CheckList: [
                      ...newTaskDesc.CheckList,
                      {
                        Checked: false,
                        CheckName: 'Новый пункт',
                        CheckNumber: newTaskDesc.CheckList.length + 1,
                      },
                    ],
                  })
                }
              >
                <div className={styles.subtasksItems}>
                  {!!newTaskDesc.CheckList?.length &&
                    newTaskDesc.CheckList.map((item, index) => (
                      <CheckItem
                        checkItemInfo={item}
                        onClick={() =>
                          setNewTaskDesc({
                            ...newTaskDesc,
                            CheckList: handleChangeCheckList(
                              newTaskDesc.CheckList,
                              'onClick',
                              '',
                              index
                            ),
                          })
                        }
                        onChange={(e) =>
                          setNewTaskDesc({
                            ...newTaskDesc,
                            CheckList: handleChangeCheckList(
                              newTaskDesc.CheckList,
                              'onChange',
                              e.target.value,
                              index
                            ),
                          })
                        }
                        canMoveUp={index !== 0}
                        handleMoveUp={() =>
                          setNewTaskDesc({
                            ...newTaskDesc,
                            CheckList: handleChangeCheckList(
                              newTaskDesc.CheckList,
                              'up',
                              '',
                              index
                            ),
                          })
                        }
                        canMoveDown={newTaskDesc.CheckList.length - 1 !== index}
                        handleMoveDown={() =>
                          setNewTaskDesc({
                            ...newTaskDesc,
                            CheckList: handleChangeCheckList(
                              newTaskDesc.CheckList,
                              'down',
                              '',
                              index
                            ),
                          })
                        }
                        handleDelete={() =>
                          setNewTaskDesc({
                            ...newTaskDesc,
                            CheckList: handleChangeCheckList(
                              newTaskDesc.CheckList,
                              'delete',
                              '',
                              index
                            ),
                          })
                        }
                        key={item.CheckNumber}
                        canConvertToTask={false}
                        createTask={() => {}}
                      />
                    ))}
                </div>
              </CheckList>
              {/* <FilePreviewContainer files={taskFiles} handleDeleteFile={handleDeleteFile} /> */}
              {/* <div className={styles.fileItems}>
                {!!taskFiles.length &&
                  taskFiles.map((item) => (
                    <div className={styles.fileItem} key={item.name}>
                      <img src={item.link} alt="your" />
                      <p className={styles.fileText}>{item.name}</p>
                      <button
                        className={styles.fileDelete}
                        onClick={() => handleDeleteFile(item.name)}
                      />
                    </div>
                  ))}
              </div> */}
            </div>
            <div className={styles.appInfo}>
              <NewTask
                NewTaskDesk={newTaskDesc}
                setNewTaskDesc={setNewTaskDesc}
                // handleAddNewFile={handleAddNewFile}
                // handleDeleteFile={handleDeleteFile}
                // fileItems={taskFiles}
              />
              <div className={styles.loading}>
                {isLoading ? (
                  <Preloader />
                ) : (
                  <SimpleBigButton
                    text={'Создать заявку'}
                    handleClick={() => handleSubmitForm()}
                    styling={styles.submit}
                    disabled={
                      !newTaskDesc.Initiator.UID ||
                      !newTaskDesc.Subject ||
                      !newTaskDesc.Project.UID ||
                      isLoading
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <ModalInfo
        isOpen={openResultModal}
        closeModal={() => setResultModal(false)}
        type={typeResultModal}
        textContent={newAppNumber}
      /> */}
    </main>
  );
};

export default ProtectedRoute(NewTaskDeskPage);
