import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ProjectType, TaskListType, UserType } from '@shared/types';
import { fetchDataPostSimple, fetchPOSTData } from 'api';

interface tasksState {
  isTasksLoading: boolean;
  isTasksRefreshing: boolean;
  tasksArr: TaskListType[];
  projectArr: ProjectType[];
  usersArr: UserType[];
  errorMessage: string;
}

const initialState: tasksState = {
  isTasksLoading: true,
  isTasksRefreshing: false,
  tasksArr: [],
  projectArr: [],
  usersArr: [],
  errorMessage: '',
};

const TasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    setTasksSliceIsLoading(state, action: PayloadAction<boolean>) {
      return { ...state, isTasksLoading: action.payload };
    },
    setTasksRefreshing(state, action: PayloadAction<boolean>) {
      return { ...state, isTasksRefreshing: action.payload };
    },
    setTasks(state, action: PayloadAction<TaskListType[]>) {
      return { ...state, tasksArr: action.payload };
    },
    setProjects(state, action: PayloadAction<ProjectType[]>) {
      return { ...state, projectArr: action.payload };
    },
    setUsers(state, action: PayloadAction<UserType[]>) {
      return { ...state, usersArr: action.payload };
    },
    setErrorMessage(state, action: PayloadAction<string>) {
      return { ...state, errorMessage: action.payload };
    },
  },
});

export const {
  setTasks,
  setProjects,
  setUsers,
  setErrorMessage,
  setTasksSliceIsLoading,
  setTasksRefreshing,
} = TasksSlice.actions;

export default TasksSlice.reducer;

export const fetchTasks = createAsyncThunk('tasks/fetchTasks', async (_, { dispatch }) => {
  await fetchPOSTData('tasks/list', dispatch, setTasks, setErrorMessage);
});

export const fetchProjects = createAsyncThunk('tasks/fetchProjects', async (_, { dispatch }) => {
  await fetchPOSTData('projects/list', dispatch, setProjects, setErrorMessage);
});

export const refreshTasks = createAsyncThunk('tasks/refreshTasks', (_, { dispatch }) => {
  dispatch(setTasksRefreshing(true));
  fetchDataPostSimple('tasks/list')
    .then((res) => dispatch(setTasks(res)))
    .catch((err) => dispatch(setErrorMessage(err.message)))
    .finally(() => dispatch(setTasksRefreshing(false)));
});

// export const fetchServices = createAsyncThunk('apps/fetchServices', async (_, { dispatch }) => {
//   await fetchPOSTData('Services', dispatch, setServices, setErrorMessage);
// });

export const fetchUsers = createAsyncThunk('tasks/fetchUsers', async (_, { dispatch }) => {
  await fetchPOSTData('users/list', dispatch, setUsers, setErrorMessage);
});

// export const fetchInitiators = createAsyncThunk('apps/fetchInitiators', async (_, { dispatch }) => {
//   await fetchPOSTData('Initiators', dispatch, setInitiators, setErrorMessage);
// });
