import { NewTaskStatusEnum, TaskStatusEnum } from '@shared/enums';
import { handleBackgroundStatus } from '@shared/hooks';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import SelectArrow from './assets/select_arrow.svg';
import closeIcon from './assets/close.svg';

// const Link = ({ className, children }) => <a className={className}>{children}</a>;
// https://styled-components.com/docs/api

const SelectContainer = styled.div`
  margin-left: auto;
  padding: 0 40px 8px 0;
  background: url(${(props) => props.theme.backImage}) no-repeat;
  background-position: bottom 12px right 13px;
  background-size: 20px 12px;
  position: relative;
`;

const StyledSpanStatus = styled.span`
  color: #f8f6ff;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.13px;
  padding: 8px;
  border-radius: 20px;
  background: ${(props) => props.theme.background};
  display: block;
`;

const StyledDialog = styled.dialog`
  margin: 0;
  padding: 15px 10px;
  left: -100px;
  top: 30px;
  z-index: 3;
  min-width: 200px;
  width: auto;
  box-sizing: border-box;
  border: 1px solid #bfc8e8;
  border-radius: 5px;
  box-shadow: 3px 3px 16px 0px rgba(0, 0, 0, 0.08);
  animation: slidein 0.3s;

  @keyframes slidein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const StyledFlexHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledDialogHeading = styled.h5`
  color: #bfc8e8;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const StyledCloseButton = styled.button`
  width: 20px;
  height: 20px;
  background: url(${closeIcon}) center no-repeat;
  background-size: 20px;
  border: none;
`;

const StyledDialogItems = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  max-height: 250px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
    padding: 0;
  }
`;

const StyledDialogLabel = styled.label`
  text-align: left;
`;

const StyledDialogItem = styled.div`
  display: flex;
  gap: 10px;
`;

const StyledDialogItemTitle = styled.h5`
  color: #363942;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
`;

interface IProps {
  currOption: NewTaskStatusEnum | TaskStatusEnum;
  options: NewTaskStatusEnum[] | TaskStatusEnum[];
  setCurrOption: (e: NewTaskStatusEnum | TaskStatusEnum) => void;
  disabled?: boolean;
}

const StatusSelect = ({ currOption, options, setCurrOption, disabled = false }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currValues, setCurrValues] = useState<NewTaskStatusEnum[] | TaskStatusEnum[]>([]);

  useEffect(() => {
    setCurrValues(options);
  }, [options]);

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('click', onClick);
    }
    return () => window.removeEventListener('click', onClick);
  }, [isOpen]);

  const handleToggleDialog = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
    // (!isOpen || e.target === e.currentTarget) && setIsOpen(true);
    (!isOpen || e.target === e.currentTarget) &&
    setTimeout(() => {
      setIsOpen(true);
    }, 100);

  const onClick = (e: MouseEvent) => {
    if (e.target instanceof Element && !e.target.closest('#status')) {
      setIsOpen(false);
    }
  };

  const handleSubmit = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    v: NewTaskStatusEnum | TaskStatusEnum
  ) => {
    e.preventDefault();
    setCurrOption(v);
    setTimeout(() => {
      setIsOpen(false);
    }, 1000);
  };

  return (
    <SelectContainer
      onClick={!!currValues.length ? (e) => handleToggleDialog(e) : (e) => e.preventDefault()}
      id={'status'}
      theme={{ backImage: !!currValues.length && !disabled && SelectArrow }}
    >
      <StyledSpanStatus theme={{ background: handleBackgroundStatus(currOption || 'Новая') }}>
        {currOption && currOption.toString() === 'ВРаботе' ? 'В работе' : currOption}
      </StyledSpanStatus>
      {isOpen && !disabled && (
        <StyledDialog open={isOpen}>
          <StyledFlexHeading>
            <StyledDialogHeading>Выберите статус</StyledDialogHeading>
            <StyledCloseButton onClick={() => setIsOpen(false)} />
          </StyledFlexHeading>
          <StyledDialogItems>
            {currValues &&
              currValues.map((item) => (
                <StyledDialogItem key={item} onClick={(e) => handleSubmit(e, item)}>
                  <input
                    type="radio"
                    id={item}
                    checked={item === currOption}
                    onChange={(e) => e.preventDefault()}
                  />
                  <StyledDialogLabel htmlFor={item}>
                    <StyledDialogItemTitle>
                      {item.toString() === 'ВРаботе' ? 'В работе' : item}
                    </StyledDialogItemTitle>
                  </StyledDialogLabel>
                </StyledDialogItem>
              ))}
          </StyledDialogItems>
        </StyledDialog>
      )}
    </SelectContainer>
  );
};

export default StatusSelect;
