import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import styles from './TaskMobilePage.module.scss';

import Header from '@components/Header/Header';
import { Footer } from '@components/Footer/Footer';
import ProtectedRoute from '@shared/HOC/ProtectedRoute';
import { TaskDescDefault } from '@shared/constants';
import { TaskCommentType, TaskDescType } from '@shared/types';
import { AuthContext } from '@contexts/CurrUserContext';
import { refreshTasks } from '@redux/slices/tasksSlice';
import { TasksDispatch } from '@redux/store';
import { fetchDataPostSimple, fetchDataPatchSimple } from 'api';
import Task from '@components/Tasks/Task';
import { Comment } from '@components/UI/Comment';
import { SimpleButton } from '@components/UI/Button';
import { ChatContainer } from '@components/UI/NewComment';
import { handleNewTextComment } from '@shared/hooks';

const TaskMobilePage = () => {
  const [taskDesc, setTaskDesk] = useState<TaskDescType>(TaskDescDefault);
  const [commArr, setCommArr] = useState<TaskCommentType[]>([]);
  const [newComment, setNewComment] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { userInfo, isLogged } = useContext(AuthContext);
  const { task } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<TasksDispatch>();
  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    setIsLoading(true);
    !!task &&
      isLogged &&
      fetchDataPostSimple('tasks/open', { UID: task })
        .then((res) => setTaskDesk({ ...res, isChanged: false }))
        .then(() => fetchDataPostSimple('tasks/comments', { UID: task }))
        .then((res) => setCommArr(res))
        // .catch(() => console.log('/404'))
        .catch(() => navigate('/404'))
        .finally(() => setIsLoading(false));
  }, [navigate, task, isLogged]);

  // useEffect(() => {
  //   messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  // }, [commArr]);

  const handleEditApp = () => {
    setIsLoading(true);
    const reqBody = {
      UID: taskDesc.UID,
      Subject: taskDesc.Subject,
      Note: taskDesc.Note,
      Status: taskDesc.Status,
      Project: taskDesc.Project.UID,
      DeadLine: taskDesc.DeadLine,
    };
    !!taskDesc.Executor.UID && Object.assign(reqBody, { Executor: taskDesc.Executor.UID });
    fetchDataPatchSimple('tasks/edit', reqBody)
      .then(() => {
        // setTypeResultModal(ModalType['edit']);
        // setResultModal(true);
        setTaskDesk({ ...taskDesc, isChanged: false });
        dispatch(refreshTasks());
      })
      .catch((err) => {
        console.error(err);
        // setTypeResultModal(ModalType['err']);
        // setResultModal(true);
      })
      .finally(() => setIsLoading(false));
  };

  const handleNewComment = () => {
    handleNewTextComment(
      newComment,
      setNewComment,
      setIsLoading,
      taskDesc.UID,
      setCommArr,
      commArr,
      userInfo.UID,
      userInfo.Name
    );
  };

  return (
    <main className={styles.page}>
      <Header pageName={`Задача #${+taskDesc.Number}`} />
      <div className={styles.appContainer}>
        <div className={styles.heading}>
          <span className={styles.formBackground}></span>
          <Task taskDesc={taskDesc} setTaskDesk={setTaskDesk} />
        </div>
        <div className={styles.body}>
          {commArr &&
            commArr.map((comm, i) => (
              <Comment
                imgLink={''}
                user={comm.SentFrom}
                title={comm.Note}
                date={comm.CommentDateTime}
                key={i}
                isAdmin={comm.SentFrom.UID === userInfo.UID}
                styling={{ headColor: '#363942' }}
              />
            ))}
          <div ref={messagesEndRef} />
        </div>
      </div>
      <div className={styles.chatContainer}>
        <ChatContainer
          // handleUploadFile={handleSetNewFileComment}
          value={newComment}
          handleChange={(e) => setNewComment(e.target.value)}
          handleSubmit={() => handleNewComment()}
          isLoading={isLoading}
        />
      </div>
      {taskDesc.isChanged && (
        <div className={styles.buttons}>
          <SimpleButton text={'Отменить'} secondary handleClick={() => window.location.reload()} />
          <SimpleButton text={'Сохранить'} handleClick={() => handleEditApp()} />
        </div>
      )}
      <Footer />
    </main>
  );
};

export default ProtectedRoute(TaskMobilePage);
