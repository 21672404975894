import React, { useContext, useLayoutEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { AuthContext } from '@contexts/CurrUserContext';
import { handleAuth } from 'api';
import { TasksDispatch } from '@redux/store';
import { fetchProjects, fetchUsers, setTasksSliceIsLoading } from '@redux/slices/tasksSlice';
import { requestForToken } from '../../firebase';
// import { addNotificationsNumber } from '@redux/slices/notificationSlice';
// import { setSavedFilterState } from '@redux/slices/filterSlices';
import { emptyPass } from '@shared/constants';

import AuthPage from '@webPage/AuthPage/AuthPage';
import DefDeskPage from '@webPage/Desktop/DefDeskPage/DefDeskPage';
import DefMobilePage from '@webPage/Mobile/DefMobilePage/DefMobilePage';
// import ApplicationsDeskPage from '@webPage/Desktop/ApplicationsDeskPage/ApplicationsDeskPage';
// import ApplicationsMobilePage from '@webPage/Mobile/ApplicationsMobilePage/ApplicationsMobilePage';
// import UsersDescPage from '@webPage/Desktop/UsersDescPage/UsersDescPage';
// import UsersMobilePage from '@webPage/Mobile/UsersMobilePage/UsersMobilePage';
// import MoreDeskPage from '@webPage/Desktop/MoreDeskPage/MoreDeskPage';
// import MoreMobilePage from '@webPage/Mobile/MoreMobilePage/MoreMobilePage';
// import NotificationDeskPage from '@webPage/Desktop/NotificationDeskPage/NotificationDeskPage';
// import NotificationMobilePage from '@webPage/Mobile/NotificationMobilePage/NotificationMobilePage';
// import PasswordPage from '@webPage/PasswordPage/PasswordPage';
import NoDeskPage from '@webPage/Desktop/NoDeskPage/NoDeskPage';
import NoMobilePage from '@webPage/Mobile/NoMobilePage/NoMobilePage';
import TasksMobilePage from '@components/Pages/Mobile/TasksMobilePage';
import TasksDeskPage from '@components/Pages/Desktop/TasksDeskPage';
import TaskMobilePage from '@components/Pages/Mobile/TaskMobilePage';
import TaskDeskPage from '@components/Pages/Desktop/TaskDeskPage';
import NewTaskMobilePage from '@components/Pages/Mobile/NewTaskMobilePage';
import NewTaskDeskPage from '@components/Pages/Desktop/NewTaskDeskPage';
// import KnowBaseDeskPage from '@webPage/Desktop/KnowBaseDeskPage/KnowBaseDeskPage';
// import KnowBaseMobilePage from '@webPage/Mobile/KnowBaseMobilePage/KnowBaseMobilePage';

// const ApplicationDeskPage = lazy(
//   () => import('@webPage/Desktop/ApplicationDeskPage/ApplicationDeskPage')
// );
// const ApplicationMobilePage = lazy(
//   () => import('@webPage/Mobile/ApplicationMobilePage/ApplicationMobilePage')
// );
// const UserDescPage = lazy(() => import('@webPage/Desktop/UserDescPage/UserDescPage'));
// const UserMobilePage = lazy(() => import('@webPage/Mobile/UserMobilePage/UserMobilePage'));
// const NewApplicationDeskPage = lazy(
//   () => import('@webPage/Desktop/NewApplicationDeskPage/NewApplicationDeskPage')
// );
// const NewApplicationMobilePage = lazy(
//   () => import('@webPage/Mobile/NewApplicationMobilePage/NewApplicationMobilePage')
// );
// const ProfileDeskPage = lazy(() => import('@webPage/Desktop/ProfileDeskPage/ProfileDeskPage'));
// const ProfileMobilePage = lazy(() => import('@webPage/Mobile/ProfileMobilePage/ProfileMobilePage'));
// const KnowItemDeskPage = lazy(() => import('@webPage/Desktop/KnowItemDeskPage/KnowItemDeskPage'));
// const KnowItemMobilePage = lazy(
//   () => import('@webPage/Mobile/KnowItemMobilePage/KnowItemMobilePage')
// );
// const NewUserDeskPage = lazy(() => import('@webPage/Desktop/NewUserDeskPage/NewUserDeskPage'));
// const NewUserMobilePage = lazy(() => import('@webPage/Mobile/NewUserMobilePage/NewUserMobilePage'));
// const ApplicationFilesDeskPage = lazy(
//   () => import('@webPage/Desktop/ApplicationFilesDeskPage/ApplicationFilesDeskPage')
// );
// const ApplicationFilesMobilePage = lazy(
//   () => import('@webPage/Mobile/ApplicationFilesMobilePage/ApplicationFilesMobilePage')
// );

function App() {
  const [isMobile, setIsMobile] = useState(document.documentElement.scrollWidth < 1000);
  const { login, authLogged, logout } = useContext(AuthContext);
  const dispatch = useDispatch<TasksDispatch>();
  // const { notificationsNumber } = useSelector((state: RootState) => state.notifications);

  // слушатель изменения ширины страницы
  useLayoutEffect(() => {
    const handlePageWidth = () => {
      setIsMobile(document.documentElement.scrollWidth < 990);
    };
    handlePageWidth();
    window.addEventListener('resize', handlePageWidth);
    return () => window.removeEventListener('resize', handlePageWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // срабатывает при перезагрузке страницы и прокидывает данные в общий контекст
  useLayoutEffect(() => {
    const { pass } = JSON.parse(localStorage.getItem('userInfo') || emptyPass);
    !!pass &&
      handleAuth(pass)
        .then((res) => login(res))
        // .then(() => dispatch(fetchTasks()))
        .then(() => dispatch(fetchProjects()))
        .then(() => dispatch(fetchUsers()))
        .then(() => requestForToken())
        .catch((err) => (err === 401 || err === 403 ? logout() : console.error(err)))
        .finally(() => dispatch(setTasksSliceIsLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authLogged]);

  // загрузка уведомлений
  // useEffect(() => {
  //   const { pass } = JSON.parse(localStorage.getItem('userInfo') || emptyPass);
  //   const interval = setInterval(() => {
  //     // if (notificationsNumber !== 0) clearInterval(interval);
  //     // else
  //     !!pass &&
  //       fetchDataPostSimple('NumberNewMessages')
  //         .then((res) => {
  //           dispatch(addNotificationsNumber(res));
  //           const { Number } = res;
  //           // Number !== 0 && clearInterval(interval);
  //           // здесь замыкается notificationsNumber - нужно будет решить
  //           +Number !== +notificationsNumber && dispatch(refreshApps());
  //         })
  //         .catch((err) => console.error(err));

  //     // dispatch(fetchNotificationsNumber());
  //   }, 60000);
  //   return () => clearInterval(interval);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [notificationsNumber]);

  return (
    <>
      <Routes>
        <Route path="" element={isMobile ? <DefMobilePage /> : <DefDeskPage />} />
        <Route path="auth" element={<AuthPage />} />
        <Route path="tasks">
          <Route index element={isMobile ? <TasksMobilePage /> : <TasksDeskPage />} />
          <Route path="new" element={isMobile ? <NewTaskMobilePage /> : <NewTaskDeskPage />} />
          <Route path=":task" element={isMobile ? <TaskMobilePage /> : <TaskDeskPage />} />
          {/* <Route
            path=":application/files"
            element={isMobile ? <ApplicationFilesMobilePage /> : <ApplicationFilesDeskPage />}
          /> */}
        </Route>
        {/* <Route
          path="notifications"
          element={isMobile ? <NotificationMobilePage /> : <NotificationDeskPage />}
        />
        <Route path="more" element={isMobile ? <MoreMobilePage /> : <MoreDeskPage />} />
        <Route path="profile" element={isMobile ? <ProfileMobilePage /> : <ProfileDeskPage />} />
        <Route path="knowledge">
          <Route index element={isMobile ? <KnowBaseMobilePage /> : <KnowBaseDeskPage />} />
          <Route
            path=":article"
            element={isMobile ? <KnowItemMobilePage /> : <KnowItemDeskPage />}
          />
        </Route> */}
        {/* <Route path="users">
          <Route index element={isMobile ? <UsersMobilePage /> : <UsersDescPage />} />
          <Route path="new-user" element={isMobile ? <NewUserMobilePage /> : <NewUserDeskPage />} />
          <Route path=":user" element={isMobile ? <UserMobilePage /> : <UserDescPage />} />
        </Route> */}

        {/* <Route path="edit-password">
          <Route index element={<PasswordPage isEditPageType={true} />} />
          <Route path=":info" element={<PasswordPage isEditPageType={true} />} />
        </Route>
        <Route path="new-password">
          <Route index element={<PasswordPage isEditPageType={false} />} />
          <Route path=":info" element={<PasswordPage isEditPageType={false} />} />
        </Route> */}

        <Route path="*" element={isMobile ? <NoMobilePage /> : <NoDeskPage />} />
      </Routes>
    </>
  );
}

export default App;
