import styled from 'styled-components';
import attachLogo from './assets/logo_attachment.svg';
import editLogo from './assets/logo_edit.svg';
import { useRef, useEffect } from 'react';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 20px;
  width: 100%;
  min-height: 40px;
  padding-bottom: ${(props) => (props.theme.isMac ? '25px' : '0')};
`;

const StyledMessageContainer = styled.div`
  position: relative;
  width: 100%;
`;

const StyledFileInputContainer = styled.label`
  position: absolute;
  left: 10px;
  bottom: 10px;
  z-index: 3;
  width: 30px;
  height: 30px;
  background: url(${attachLogo}) center no-repeat;
`;

const StyledInputFile = styled.input`
  position: relative;
  opacity: 0;
  z-index: 2;
  width: 30px;
  height: 30px;
  display: none;
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  padding: 10px 0 10px 50px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 500;
  outline: none;
  border-radius: 8px 8px 0 8px;
  min-height: 40px;
  outline: none;
  border: 1px solid #363942;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
    padding: 0;
  }
  }
`;

const StyledSpanTriangle = styled.span`
  position: absolute;
  bottom: 4px;
  right: -4px;
  width: 0;
  height: 0;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #363942;
`;

const StyledLoader = styled.div`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  margin-bottom: 4px;
  background: url(${editLogo}) center no-repeat;
  background-size: 20px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: -5px;
    top: -5px;
    bottom: -5px;
    right: -5px;
    border: 5px solid #000000;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const StyledSubmitButton = styled.button`
  width: 40px;
  height: 40px;
  background: url(${editLogo}) center no-repeat;
  background-size: 20px;
  flex-shrink: 0;
  background-color: #3a49f9;
  border-radius: 50%;
  margin-bottom: 4px;
  transition: all 0.3s ease;

  &:disabled {
    background-color: transparent;
  }
`;

interface ITextAreaProps {
  // handleUploadFile: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  handleChange: (evt: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value: string;
  handleSubmit: () => void;
  isLoading?: boolean;
}

export const ChatContainer = ({
  // handleUploadFile,
  handleChange,
  value,
  handleSubmit,
  isLoading = false,
}: ITextAreaProps) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = '0px';
      const { scrollHeight } = textAreaRef.current;
      textAreaRef.current.style.height = `${scrollHeight}px`;
    }
  }, [textAreaRef, value]);

  return (
    <StyledContainer
      theme={{
        isMac:
          navigator.userAgent.includes('Mac') &&
          window.matchMedia('(display-mode: standalone)').matches,
      }}
    >
      <StyledMessageContainer>
        {/* <StyledFileInputContainer htmlFor={'file-upload'}>
          <StyledInputFile id="file-upload" type="file" onChange={(e) => handleUploadFile(e)} />
        </StyledFileInputContainer> */}
        <StyledTextArea
          ref={textAreaRef}
          value={value}
          onChange={handleChange}
          required
          placeholder={'добавить комментарий'}
          onKeyDown={(e) => e.key === 'Enter' && e.shiftKey === false && handleSubmit()}
        />
        <StyledSpanTriangle />
      </StyledMessageContainer>
      {isLoading ? (
        <StyledLoader />
      ) : (
        <StyledSubmitButton onClick={handleSubmit} disabled={!value} />
      )}
    </StyledContainer>
  );
};
