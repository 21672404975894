import React, { useState } from 'react';
import cn from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import styles from './NavSideBar.module.scss';
import { ExtendButton } from '@components/UI/ExtendButton';
import CompanyLogo from '@assets/navBar/company_logo.png';
import CompanyLogoSmall from '@assets/navBar/company_logo_small.png';

export const NavSideBar = () => {
  const [isExtend, setIsExtend] = useState(true);
  const router = useLocation();

  const activeLink = (v: string) => router.pathname === v && styles.link_active;

  return (
    <nav className={cn(styles.nav, !isExtend && styles.nav_hide)}>
      {/* <button
        className={cn(styles.extendButton, !isExtend && styles.extendButton_active)}
        onClick={() => setIsExtend(!isExtend)}
      /> */}
      <ExtendButton
        onClick={() => setIsExtend(!isExtend)}
        backColor={'var(--gray, #8391A5)'}
        arrowDirection={isExtend ? 'right' : 'left'}
        className={styles.extendButton}
      />
      <Link to={'/'} className={styles.imageLink}>
        <img
          className={cn(styles.logo, !isExtend && styles.logo_hide)}
          src={!isExtend ? CompanyLogoSmall : CompanyLogo}
          alt="company"
        />
      </Link>
      <Link to={'/tasks'} className={cn(styles.link, activeLink('/tasks'))}>
        <span className={styles.tasks} />
        <p className={cn(styles.text, !isExtend && styles.text_hide)}>Задачи</p>
      </Link>
      <Link to={'/users'} className={cn(styles.link, activeLink('/users'))}>
        <span className={styles.users} />
        <p className={cn(styles.text, !isExtend && styles.text_hide)}>Пользователи</p>
      </Link>
      <Link to={'/reports'} className={cn(styles.link, activeLink('/reports'))}>
        <span className={styles.reports} />
        <p className={cn(styles.text, !isExtend && styles.text_hide)}>Мониторинг</p>
      </Link>
      <Link to={'/more'} className={cn(styles.link, activeLink('/more'))}>
        <span className={styles.more} />
        <p className={cn(styles.text, !isExtend && styles.text_hide)}>Настройки</p>
      </Link>
      <span className={cn(styles.copyright, !isExtend && styles.copyright_hide)}>
        Сделано в{' '}
        <a
          href="https://rixap.ru/"
          target="_blank"
          rel="noreferrer"
          className={styles.copyright__link}
        >
          Rixap
        </a>
      </span>
    </nav>
  );
};
