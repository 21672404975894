// import React, { useEffect, useState } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';
// import imageCompression from 'browser-image-compression';

import styles from './NewTask.module.scss';
import { NewTaskDescType } from '@shared/types';
import { NewTaskStatusEnum } from '@shared/enums';
import { RootState } from '@redux/store';
// import { getBase64 } from '@shared/hooks';
// import { CategoryName, CategoryShowName, ServiceName, ServiceShowName } from '@shared/constants';

// import SimpleSearchInput from '@components/UI/SimpleSearchInput';
import SearchInput from '@components/UI/SearchInput';
import StatusSelect from '@components/UI/StatusSelect';
// import DescTextArea from '@components/UI/DescTextArea';
import { SimpleTextArea } from '@components/UI/SimpleTextArea';
import { CheckList } from '@components/UI/CheckList';
import { CheckItem } from '@components/UI/CheckItem';
import CustomEditor from '@components/UI/CustomEditor';
import { handleChangeCheckList } from '@shared/hooks';
import SimpleSearchInput from '@components/UI/SimpleSearchInput';
// import FilePreviewContainer from '@components/UI/FilePreviewContainer';
// import AddressSearchInput from '@components/UI/AddressSearchInput';

interface IProps {
  NewTaskDesk: NewTaskDescType;
  setNewTaskDesc: (v: NewTaskDescType) => void;
  // handleAddNewFile: (v: { file: string; name: string; link: string }) => void;
  // handleDeleteFile: (name: string) => void;
  // fileItems: { file: string; name: string; link: string }[];
}

const NewApplication: React.FC<IProps> = ({
  NewTaskDesk,
  setNewTaskDesc,
  // handleAddNewFile,
  // handleDeleteFile,
  // fileItems,
}) => {
  const { usersArr, projectArr } = useSelector((state: RootState) => state.tasks);

  // const [isLoading, setIsLoading] = useState(false);
  // const [addressSugg, setAddressSugg] = useState<{ Adress: string }[]>([]);

  // useEffect(() => {
  //   !!NewAppDesk.Service.UID &&
  //     fetchDataPost('Categories', NewAppDesk.Service.UID)
  //       .then((res) => setCategoriesArr(res))
  //       .then(() => fetchDataPost('users', NewAppDesk.Service.UID))
  //       .then((users) => {
  //         let exeArr: UserType[] = [];
  //         users.map(
  //           // добавлена возможность назначать старшему исполнителю заявки на себя
  //           (item: UserType) => (item.IsExecutor || item.IsSeniorExecutor) && exeArr.push(item)
  //         );
  //         setExecutorsArr(exeArr);
  //         exeArr.length === 1 &&
  //           setNewAppDesk({
  //             ...NewAppDesk,
  //             Executor: { UID: exeArr[0].UID, Name: exeArr[0].Name || exeArr[0].FullName },
  //           });
  //       })
  //       .catch((err) => console.error(err));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [NewAppDesk.Service]);

  const handleUpdateTaskDesk = (updatedFields: Partial<NewTaskDescType>) => {
    setNewTaskDesc({
      ...NewTaskDesk,
      ...updatedFields,
    });
  };

  const handleChangeStatus = (e: NewTaskStatusEnum) => {
    e === NewTaskStatusEnum['Draft']
      ? handleUpdateTaskDesk({
          Status: e,
          Executor: { UID: '', Name: '' },
        })
      : handleUpdateTaskDesk({
          Status: e,
          Executor: { UID: '', Name: '' },
        });
  };

  // const handleSetNewFileComment = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   if (!e.target.files?.length) return;
  //   const currFile = e.target.files[0];
  //   e.target.value = '';
  //   if (!currFile || fileItems.find((item) => item.name === currFile.name)) return;
  //   let fileItem = '';
  //   setIsLoading(true);
  //   imageCompression(currFile, {
  //     maxSizeMB: 1,
  //     maxWidthOrHeight: 1920,
  //   })
  //     .then((res) => getBase64(res))
  //     .then((result) => {
  //       if (currFile.type.split('/')[0] === 'image') {
  //         fileItem = `<img src="${result}" alt="${currFile.name}" />`;
  //         handleAddNewFile({
  //           file: fileItem,
  //           name: currFile.name,
  //           link: URL.createObjectURL(currFile),
  //         });
  //       }
  //     })
  //     .catch((err) => console.error(err))
  //     .finally(() => setIsLoading(false));
  // };

  // const handleChangeAppAddress = (v: string) => {
  //   !!v &&
  //     fetchPostRequest('RequestAddress', { Request: v })
  //       .then((res) => setAddressSugg(res))
  //       .catch((err) => console.log(err));
  //   setNewAppDesk({ ...NewAppDesk, Address: v });
  // };

  return (
    <>
      <div className={styles.heading}>
        <span className={styles.formBackground}></span>
        <label className={styles.label}>
          <StatusSelect
            currOption={NewTaskDesk.Status}
            options={Object.values(NewTaskStatusEnum)}
            setCurrOption={(e) => handleChangeStatus(e as NewTaskStatusEnum)}
          />
        </label>
        <SimpleSearchInput
          currOption={NewTaskDesk.Project.Name}
          options={projectArr}
          setCurrOption={(v) =>
            handleUpdateTaskDesk({
              Project: v,
            })
          }
          styling={{
            margin: '10px 0 0',
            headColor: '#FFF',
            textColor: '#FFF',
            dialogColor: '#363942',
          }}
          heading={'Проект'}
          dialogText={'Выберите проект'}
          name="Service"
          disabled={false}
        />
        <SearchInput
          currOption={NewTaskDesk.Initiator}
          options={usersArr}
          setCurrOption={(e) =>
            handleUpdateTaskDesk({
              Initiator: e,
            })
          }
          styling={{
            margin: '10px 0 0',
            padding: '0 0 0',
            headColor: '#FFFFFF',
            textColor: '#FFFFFF',
          }}
          heading={'Инициатор'}
          dialogText={'Выбрать инициатора'}
          disabled={false}
          name="Initiator"
        />
        <SearchInput
          currOption={NewTaskDesk.Executor}
          options={usersArr}
          setCurrOption={(e) =>
            handleUpdateTaskDesk({
              Executor: e,
            })
          }
          styling={{
            margin: '10px 0 0',
            padding: '0 0 0',
            headColor: '#FFFFFF',
            textColor: '#FFFFFF',
          }}
          heading={'Исполнитель'}
          dialogText={'Выбрать исполнителя'}
          name="Executor"
        />
      </div>

      <div className={styles.body}>
        <SimpleTextArea
          placeholder={'Тема задачи'}
          name={'taskName'}
          value={NewTaskDesk.Subject}
          handleChange={(e) => handleUpdateTaskDesk({ Subject: e.target.value })}
          styling={{ padding: '15px 50px 0 0', headColor: '#BFC8E8', textColor: '#363942' }}
        />
        {/* <DescTextArea
          value={NewTaskDesk.Note}
          handleChange={(e) => setNewTaskDesk({ ...NewTaskDesk, Note: e.target.value })}
          name={'Описание'}
          styling={{ padding: ' 0 0 0', margin: '19px 0 0', textColor: '#BFC8E8' }}
          handleUploadFile={(e) => console.log(1)}
          isLoading={false}
          // handleUploadFile={handleSetNewFileComment}
          // isLoading={isLoading}
        /> */}

        <CustomEditor
          value={NewTaskDesk.Note}
          handleChange={(e) => handleUpdateTaskDesk({ Note: e.value })}
          name={'Добавить описание'}
          styling={{ padding: ' 0 0 10px', margin: '15px 0 0', textColor: '#BFC8E8' }}
        />
        <CheckList
          addNewCheckItem={() =>
            setNewTaskDesc({
              ...NewTaskDesk,
              CheckList: [
                ...NewTaskDesk.CheckList,
                {
                  Checked: false,
                  CheckName: 'Новый пункт',
                  CheckNumber: NewTaskDesk.CheckList.length + 1,
                },
              ],
            })
          }
        >
          <div className={styles.subtasksItems}>
            {!!NewTaskDesk.CheckList?.length &&
              NewTaskDesk.CheckList.map((item, index) => (
                <CheckItem
                  checkItemInfo={item}
                  onClick={() =>
                    setNewTaskDesc({
                      ...NewTaskDesk,
                      CheckList: handleChangeCheckList(NewTaskDesk.CheckList, 'onClick', '', index),
                    })
                  }
                  onChange={(e) =>
                    setNewTaskDesc({
                      ...NewTaskDesk,
                      CheckList: handleChangeCheckList(
                        NewTaskDesk.CheckList,
                        'onChange',
                        e.target.value,
                        index
                      ),
                    })
                  }
                  canMoveUp={index !== 0}
                  handleMoveUp={() =>
                    setNewTaskDesc({
                      ...NewTaskDesk,
                      CheckList: handleChangeCheckList(NewTaskDesk.CheckList, 'up', '', index),
                    })
                  }
                  canMoveDown={NewTaskDesk.CheckList.length - 1 !== index}
                  handleMoveDown={() =>
                    setNewTaskDesc({
                      ...NewTaskDesk,
                      CheckList: handleChangeCheckList(NewTaskDesk.CheckList, 'down', '', index),
                    })
                  }
                  handleDelete={() =>
                    setNewTaskDesc({
                      ...NewTaskDesk,
                      CheckList: handleChangeCheckList(NewTaskDesk.CheckList, 'delete', '', index),
                    })
                  }
                  key={item.CheckNumber}
                  canConvertToTask={false}
                  createTask={() => {}}
                />
              ))}
          </div>
        </CheckList>
        {/* <FilePreviewContainer files={fileItems} handleDeleteFile={handleDeleteFile} /> */}
      </div>
    </>
  );
};

export default NewApplication;
