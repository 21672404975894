import styled from 'styled-components';
import parse, { DOMNode, HTMLReactParserOptions, domToReact } from 'html-react-parser';
import defaultAvatar from './assets/default_avatar.svg';
import { UidNameType } from '@shared/types';

const StyledComment = styled.div`
  display: flex;
  flex-direction: row;
  gap: 14px;
`;

const StyledCardImageAvatar = styled.img`
  width: 34px;
  height: 34px;
  // background-image: url(${(props) => props.theme.imgLink});
  // background-position: center;
  // background-size: 34px;
  // flex-shrink: 0;
`;

const StyledCommentMain = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  max-width: 380px;
`;
const StyledCommentFlex = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledCardText = styled.p`
  color: ${(props) => props.theme.headColor};
  text-align: left;
  font-size: 13px;
  font-family: Roboto;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.13px;
  opacity: 0.65;
  max-width: 140px;
`;

const StyledCommentBody = styled.div`
  padding: 20px;
  width: 100%;
  // max-width: 300px;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: ${(props) => (props.theme.isAdmin ? '#DAFCF2' : '#fff')};
  box-shadow: 3px 3px 16px 0px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
`;

const StyledCardHeading = styled.div`
  color: #363942;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  white-space: pre-wrap;
`;

interface IStyling {
  headColor?: string;
}

interface ITaskProps {
  imgLink: string;
  user: UidNameType;
  title: string;
  date: Date | string;
  isAdmin: boolean;
  styling: IStyling;
}

const options: HTMLReactParserOptions = {
  replace: (domNode: DOMNode) => {
    if (domNode.type === 'tag') {
      const { name, children }: any = domNode;
      if (name && (name === 'head' || name === 'meta' || name === 'style')) {
        return <></>;
      } else if (name && (name === 'html' || name === 'body' || name === 'p' || name === 'span')) {
        return <>{domToReact([...children], options)}</>;
      }
    }
  },
};

export const Comment = ({ imgLink, user, title, date, isAdmin, styling }: ITaskProps) => {
  const { headColor } = styling;

  return (
    <StyledComment>
      <StyledCardImageAvatar
        // href={`#/users/${user.UID}`}
        // theme={{ imgLink: imgLink.length ? imgLink : defaultAvatar }}
        src={imgLink.length ? imgLink : defaultAvatar}
      />
      <StyledCommentMain>
        <StyledCommentFlex>
          <StyledCardText theme={{ headColor }}>{user.Name}</StyledCardText>
          <StyledCardText theme={{ headColor }}>
            {date.toString().split('-').join('.').split('T').join(' - ')}
          </StyledCardText>
        </StyledCommentFlex>
        <StyledCommentBody theme={{ isAdmin }}>
          {!!title ? (
            <StyledCardHeading>{parse(title, options)}</StyledCardHeading>
          ) : (
            <StyledCardHeading>Описания нет</StyledCardHeading>
          )}
        </StyledCommentBody>
      </StyledCommentMain>
    </StyledComment>
  );
};
