import React, { ReactNode } from 'react';
import styled from 'styled-components';
// import addLogoMobile from './assets/add_desk.svg';
import addLogoDesktop from './assets/add_mob.svg';
// import templateLogoMobile from './assets/template_desk.svg';
// import templateLogDesktop from './assets/template_mob.svg';

const StyledContainer = styled.div`
  position: relative;
  min-height: 100px;
  border-bottom: 1px solid #bfc8e8;
  display: flex;
  flex-direction: column;
`;

const StyledHeadingText = styled.p`
  color: #bfc8e8;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const StyledButtonsContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
  margin: auto 0 5px;
`;

const StyledButtonContainer = styled(StyledButtonsContainer)`
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
`;

const StyledSpanWithLogo = styled.span`
  display: block;
  width: 23px;
  height: 23px;
  background-position: center;
  background-repeat: no-repeat;
  flex-shrink: 0;
`;

const StyledAddCheckItemButton = styled(StyledSpanWithLogo)`
  background-image: url(${addLogoDesktop});

  @media screen and (min-width: 1000px) {
    background-image: url(${addLogoDesktop});
  }
`;

// const StyledTemplateButton = styled(StyledSpanWithLogo)`
//   background-image: url(${templateLogDesktop});

//   @media screen and (min-width: 1000px) {
//     background-image: url(${templateLogDesktop});
//   }
// `;

const StyledButtonText = styled.p`
  color: #bfc8e8;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

interface ICheckListProps {
  children: ReactNode;
  addNewCheckItem: () => void;
}

export const CheckList = ({ children, addNewCheckItem }: ICheckListProps) => {
  return (
    <StyledContainer>
      <StyledHeadingText>Добавить чек-лист</StyledHeadingText>
      {children}
      <StyledButtonsContainer>
        <StyledButtonContainer onClick={() => addNewCheckItem()}>
          <StyledAddCheckItemButton />
          <StyledButtonText>Добавить элемент</StyledButtonText>
        </StyledButtonContainer>
        {/* <StyledButtonContainer>
          <StyledTemplateButton />
          <StyledButtonText>Добавить из шаблона</StyledButtonText>
        </StyledButtonContainer> */}
      </StyledButtonsContainer>
    </StyledContainer>
  );
};
