import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import editLogo from './assets/logo_edit.svg';

const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;
  position: relative;
  border: none;
  align-items: flex-start;
  width: 100%;
  margin: ${(props) => props.theme.margin};
`;

const StyledParagraph = styled.p`
  color: ${(props) => props.theme.headColor};
  font-size: 18px;
  font-family: Roboto;
  position: absolute;
  line-height: 20px;
  margin: 0;
  padding: 0;
  top: 20px;
  left: 0px;
  background-color: inherit;
  transform: translateY(-50%);
  transition: all 0.3s ease;
  padding: 0;
`;

const StyledSimpleTextArea = styled.textarea`
  overflow: hidden;
  width: 100%;
  border: none;
  min-height: 37px;
  height: auto;
  resize: none;
  color: ${(props) => props.theme.textColor};
  font-size: 23px;
  font-family: Roboto;
  font-weight: 600;
  padding: ${(props) => props.theme.padding};
  box-sizing: border-box;
  outline: none;
  // transition: all 0.3s ease;
  background: transparent;
  background: url(${editLogo}) no-repeat;
  background-position: bottom 8px right 10px;
  border-radius: 0px;

  &:focus {
    outline: none;
    border-bottom: 1px solid rgb(0, 0, 0);
  }
  &:invalid {
    outline: none;
    border-bottom: 1px solid #fc0800;
  }
  &:valid:not(:placeholder-shown):not(:focus) {
    border-bottom: 1px solid rgb(0, 255, 0);
  }
  &::placeholder {
    opacity: 0;
  }
  &:not(:placeholder-shown) + p,
  &:focus + p {
    top: 0px;
  }

  &:disabled {
    background: transparent;
    border-bottom: 1px solid #bfc8e8;
  }
`;

const StyledErrorSpan = styled.span`
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  color: #fabb18;
  opacity: 1;
  position: absolute;
  bottom: -18px;
  right: 0;
  z-index: 2;
`;

interface IStyling {
  margin?: string;
  padding?: string;
  headColor?: string;
  textColor?: string;
}

interface ITextAreaProps {
  placeholder?: string;
  name: string;
  handleChange?: (evt: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value: string;
  error?: string;
  styling: IStyling;
  disabled?: boolean;
  handleSubmit?: () => void;
}

export const SimpleTextArea = ({
  placeholder,
  handleChange,
  value,
  error,
  name,
  styling,
  disabled = false,
}: ITextAreaProps) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = '0px';
      const { scrollHeight } = textAreaRef.current;
      textAreaRef.current.style.height = `${scrollHeight}px`;
    }
  }, [textAreaRef, value]);

  const { margin, padding, headColor, textColor } = styling;

  return (
    <StyledLabel theme={{ margin }}>
      <StyledSimpleTextArea
        ref={textAreaRef}
        required={true}
        minLength={2}
        onChange={handleChange}
        value={value}
        theme={{ padding, textColor }}
        disabled={disabled}
        placeholder="some text"
      />
      <StyledParagraph theme={{ headColor }}>{placeholder}</StyledParagraph>
      {error && <StyledErrorSpan>{error}</StyledErrorSpan>}
    </StyledLabel>
  );
};
