import styled from 'styled-components';
import DoubleArrow from './assets/double_arrow.svg';

const StyledButton = styled.button`
  width: 40px;
  height: 40px;
  background-color: ${(props) => props.theme.backColor};
  background-image: url(${DoubleArrow});
  transform: rotate(${(props) => props.theme.rotateDeg}deg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px 20px;
  border-radius: 50%;
  padding: 0;
  transition: all 0.3s ease;
`;

interface IProps {
  onClick: () => void;
  backColor: string;
  arrowDirection: 'up' | 'down' | 'left' | 'right';
  className: string;
}

const handleConvertDirectionToDeg = (v: 'up' | 'down' | 'left' | 'right') => {
  switch (v) {
    case 'up':
      return '90';
    case 'down':
      return '270';
    case 'right':
      return '180';
    default:
      return '0';
  }
};

export const ExtendButton = ({ onClick, backColor, arrowDirection, className }: IProps) => {
  return (
    <StyledButton
      onClick={() => onClick()}
      theme={{ backColor, rotateDeg: handleConvertDirectionToDeg(arrowDirection) }}
      className={className}
    />
  );
};
