import React, { useContext } from 'react';

import styles from './TasksDeskPage.module.scss';
import ProtectedRoute from '@shared/HOC/ProtectedRoute';
import { AuthContext } from '@contexts/CurrUserContext';

import Header from '@components/Header/Header';
import { NavSideBar } from '@components/NavSideBar/NavSideBar';
import SubHeader from '@components/SubHeader/SubHeader';
import { Tasks } from '@components/Tasks/Tasks';

const TasksDeskPage = () => {
  const { userInfo } = useContext(AuthContext);

  return (
    <main className={styles.page}>
      <NavSideBar />
      <Header deskPageName="Техподдержка" />
      <div>
        <SubHeader
          pageName={'Задачи'}
          isCreateNewApp={
            userInfo.IsCoordinator || userInfo.IsSeniorCoordinator || userInfo.IsSeniorExecutor
          }
        />
        <div className={styles.body}>
          <Tasks />
          <div className={styles.reports}>
            <div className={styles.comments}></div>
            <div className={styles.appInfo}></div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ProtectedRoute(TasksDeskPage);
