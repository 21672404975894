export enum TaskStatusEnum {
  Draft = 'Черновик',
  Appointed = 'Назначена',
  Await = 'Ожидание',
  InProgress = 'В работе',
  Done = 'Выполнена',
  Confirmation = 'Завершена',
  Canceled = 'Отменена',
  Delayed = 'Отложена',
}

export enum NewTaskStatusEnum {
  Draft = 'Черновик',
  Appointed = 'Назначена',
}

export enum PriorityType {
  veryLow = 'Очень низкий',
  low = 'Низкий',
  medium = 'Средний',
  high = 'Важный',
  veryHigh = 'Очень важный',
}

export enum ModalType {
  edit = 'edit',
  err = 'err',
  exit = 'exit',
  newApp = 'new',
  user = 'user',
  confirm = 'confirm',
}

export enum ColumnNameEnum {
  Waiting = 'Ожидание',
  ToDo = 'Делать',
  InProgress = 'В работе',
  Done = 'Выполнена',
}
