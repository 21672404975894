import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import styles from './TaskDeskPage.module.scss';
import ProtectedRoute from '@shared/HOC/ProtectedRoute';
import { AuthContext } from '@contexts/CurrUserContext';

import Header from '@components/Header/Header';
import { NavSideBar } from '@components/NavSideBar/NavSideBar';
import SubHeader from '@components/SubHeader/SubHeader';
import { TasksModal } from '@components/Tasks/Tasks';
import { TaskCommentType, TaskDescType } from '@shared/types';
import { TaskDescDefault } from '@shared/constants';
import { handleNewTextComment } from '@shared/hooks';

import { fetchDataPatchSimple, fetchDataPostSimple } from 'api';
import Task from '@components/Tasks/Task';
import { SimpleButton } from '@components/UI/Button';
import { Comment } from '@components/UI/Comment';
import { TasksDispatch } from '@redux/store';
import { refreshTasks } from '@redux/slices/tasksSlice';
import { ChatContainer } from '@components/UI/NewComment';

const TaskDeskPage = () => {
  const [taskDesc, setTaskDesk] = useState<TaskDescType>(TaskDescDefault);
  const [commArr, setCommArr] = useState<TaskCommentType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isTasksListOpen, setTasksListOpen] = useState(false);
  const [newComment, setNewComment] = useState('');
  const { userInfo, isLogged } = useContext(AuthContext);
  const { task } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<TasksDispatch>();
  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    setIsLoading(true);
    !!task &&
      isLogged &&
      fetchDataPostSimple('tasks/open', { UID: task })
        .then((res) => setTaskDesk({ ...res, isChanged: false }))
        .then(() => fetchDataPostSimple('tasks/comments', { UID: task }))
        .then((res) => setCommArr(res))
        // .catch(() => console.log('/404'))
        .catch(() => navigate('/404'))
        .finally(() => setIsLoading(false));
  }, [navigate, task, isLogged]);

  // useEffect(() => {
  //   messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  // }, [commArr]);

  const handleEditApp = () => {
    setIsLoading(true);
    const reqBody = {
      UID: taskDesc.UID,
      Subject: taskDesc.Subject,
      Note: taskDesc.Note,
      Status: taskDesc.Status,
      Project: taskDesc.Project.UID,
      DeadLine: taskDesc.DeadLine,
    };
    !!taskDesc.Executor.UID && Object.assign(reqBody, { Executor: taskDesc.Executor.UID });
    fetchDataPatchSimple('tasks/edit', reqBody)
      .then(() => {
        // setTypeResultModal(ModalType['edit']);
        // setResultModal(true);
        setTaskDesk({ ...taskDesc, isChanged: false });
        dispatch(refreshTasks());
      })
      .catch((err) => {
        console.error(err);
        // setTypeResultModal(ModalType['err']);
        // setResultModal(true);
      })
      .finally(() => setIsLoading(false));
  };

  const handleNewComment = () => {
    handleNewTextComment(
      newComment,
      setNewComment,
      setIsLoading,
      taskDesc.UID,
      setCommArr,
      commArr,
      userInfo.UID,
      userInfo.Name
    );
  };

  return (
    <main className={styles.page}>
      <NavSideBar />
      <Header deskPageName="Техподдержка" />
      <div>
        <SubHeader pageName={'Задачи'} isCreateNewApp={true} />
        <div className={styles.body}>
          <TasksModal isTasksListOpen={isTasksListOpen} setTasksListOpen={setTasksListOpen} />
          <div className={styles.additional}>
            <div className={styles.comments}>
              <Comment
                imgLink={''}
                user={taskDesc.Initiator}
                title={taskDesc.Note}
                date={taskDesc.Date}
                isAdmin={false}
                styling={{ headColor: '#363942' }}
              />
              {commArr &&
                commArr.map((comm, i) => (
                  <Comment
                    imgLink={''}
                    user={comm.SentFrom}
                    title={comm.Note}
                    date={comm.CommentDateTime}
                    key={i}
                    isAdmin={comm.SentFrom.UID === userInfo.UID}
                    styling={{ headColor: '#363942' }}
                  />
                ))}
              <div ref={messagesEndRef} />
              <div className={styles.chatContainer}>
                <ChatContainer
                  // handleUploadFile={handleSetNewFileComment}
                  value={newComment}
                  handleChange={(e) => setNewComment(e.target.value)}
                  handleSubmit={() => handleNewComment()}
                  isLoading={isLoading}
                />
              </div>
            </div>
            <div className={styles.appInfo}>
              <Task taskDesc={taskDesc} setTaskDesk={setTaskDesk} />
              {taskDesc.isChanged && (
                <div className={styles.buttons}>
                  <SimpleButton
                    text={'Отменить'}
                    secondary
                    handleClick={() => window.location.reload()}
                  />
                  <SimpleButton text={'Сохранить'} handleClick={() => handleEditApp()} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ProtectedRoute(TaskDeskPage);
