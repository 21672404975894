import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { handleBackgroundStatus } from '@shared/hooks';
import { TaskListType } from '@shared/types';

import cardTimeLogo from './assets/logo_time.svg';
import cardCommLogo from './assets/logo_message.svg';
import cardFilesLogo from './assets/logo_files.svg';
import defaultAvatar from './assets/default_avatar.svg';
// import projectLogo from './assets/logo_project.svg';
// import cardAttachmentLogo from '@assets/logo_attachment.svg';
// import organizationAvatar from '@assets/default_organization.svg';
// import taskTypeTelegamm from './assets/task_type_telegramm.svg';
// import taskTypeMail from './assets/task_type_mail.svg';

// const StyledProject = styled.div`
//   width: 203px;
//   height: 202px;
//   border-radius: 20px;
//   background: var(--back-gradient);
//   display: flex;
//   flex-direction: column;
//   padding: 22px 20px 20px 22px;
//   opacity: ${(props) => props.theme.opacity};

//   &:hover {
//     opacity: 0.8;
//   }
// `;

// const StyledProjectHeading = styled.div`
//   display: flex;
//   flex-wrap: nowrap;
//   align-items: center;
//   gap: 10px;
// `;

// const StyledProjectLogo = styled.span`
//   width: 30px;
//   height: 30px;
//   background: url(${projectLogo}) center no-repeat;
// `;

// const StyledProjectTitle = styled.p`
//   color: #fff;
//   font-size: 18px;
//   font-family: Roboto;
//   font-weight: 600;
// `;

// const StyledProjectText = styled.p`
//   color: #fff;
//   font-size: 23px;
//   font-family: Roboto;
//   font-weight: 600;
//   line-height: 129.5%;
//   margin-top: 22px;
// `;

// const StyledProjectDate = styled.p`
//   color: #fff;
//   font-size: 16px;
//   font-family: Roboto;
//   font-weight: 400;
//   margin-top: 27px;
// `;

// interface IProjectProps {
//   active: boolean;
//   heading: string;
//   text: string;
//   date: string;
//   link: string;
// }

// export const ProjectCard = ({ active, heading, text, date, link }: IProjectProps) => (
//   <a href={link}>
//     <StyledProject theme={{ opacity: active ? 1 : 0.5 }}>
//       <StyledProjectHeading>
//         <StyledProjectLogo />
//         <StyledProjectTitle>{heading}</StyledProjectTitle>
//       </StyledProjectHeading>
//       <StyledProjectText>{text}</StyledProjectText>
//       <StyledProjectDate>{date}</StyledProjectDate>
//     </StyledProject>
//   </a>
// );

const StyledTask = styled.div`
  min-height: 150px;
  max-width: 380px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: ${(props) => (props.theme.isActive ? '#e8eafd' : '#fff')};
  box-shadow: 3px 3px 16px 0px rgba(0, 0, 0, 0.08);
  padding: 15px 10px 16px 12px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 3px 3px 16px 0px rgba(0, 0, 0, 0.3);
    transform: scale(1.01);
  }
`;

const StyledTaskCornerSpan = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-top: 10px solid ${(props) => props.theme.main};
  border-left: 10px solid ${(props) => props.theme.main};
  border-bottom: 10px solid transparent;
  border-right: 10px solid transparent;
`;

const StyledCardFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 5px;
`;

const StyledCardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 0 3px;
`;

const StyledHeading = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 0 0 9px;
`;

const StyledCardItems = styled.div`
  display: flex;
  gap: 3px;
  flex-direction: column;
`;

const StyledCardItem = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

const StyledCardImage = styled.img`
  width: 16px;
  height: 16px;
`;

// const StyledCardOrg = styled.img`
//   width: 20px;
//   height: 20px;
// `;

const StyledCardText = styled.p`
  color: ${(props) => (props.theme.textColor ? props.theme.textColor : '#363942')};
  text-align: left;
  font-size: 13px;
  font-family: Roboto;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.13px;
  opacity: 0.65;
  max-width: ${(props) => (props.theme.maxWidth ? props.theme.maxWidth : 'none')};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledCardNotificationText = styled.p`
  opacity: 0.65;
  color: #087652;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.13px;
`;

const StyledCardHeading = styled.h3`
  color: #363942;
  text-align: left;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.16px;
`;

const StyledStatus = styled.span`
  background: ${(props) => props.theme.main};
  padding: 8px;
  border-radius: 20px;
  color: #f8f6ff;
  text-align: center;
  font-size: 13px;
  font-family: Roboto;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.13px;
  margin-left: auto;
  flex-shrink: 0;
`;

const StyledCardLine = styled.span`
  display: block;
  width: 100%;
  height: 1px;
  opacity: 0.6;
  background: #bfbfbf;
  margin: 14px 0 15px;
`;

const StyledCardAdditional = styled.div`
  display: flex;
  gap: 10px;
`;

const StyledCardSpanLogo = styled.span`
  display: block;
  width: 16px;
  height: 16px;
  background: url(${(props) => props.theme.logo}) center no-repeat;
`;

interface ITaskProps {
  cardInfo: TaskListType;
  notificationText?: string;
  onClickHandler: (v: TaskListType) => void;
  isActive?: boolean;
}

export const Card = ({ isActive, onClickHandler, cardInfo }: ITaskProps) => {
  return (
    <Link to={`/tasks/${cardInfo.UID}`}>
      <StyledTask theme={{ isActive }} onClick={() => onClickHandler(cardInfo)}>
        {cardInfo.Executor?.UID && (
          <StyledTaskCornerSpan theme={{ main: handleBackgroundStatus(cardInfo.Status) }} />
        )}
        <StyledCardFlexContainer>
          <StyledHeading>
            <StyledCardItems>
              {/* <StyledCardItem>
                <StyledCardOrg
                  src={organizationAvatar}
                  alt={cardInfo.Initiator?.Name || cardInfo.Project?.Name}
                />
                <StyledCardText theme={{ maxWidth: '100px' }}>
                  {cardInfo.Organization?.Name || cardInfo.Service.Name || ServiceName}
                </StyledCardText>
              </StyledCardItem> */}
              <StyledCardItem>
                <StyledCardImage src={defaultAvatar} alt={cardInfo.Initiator.Name} />
                <StyledCardText theme={{ maxWidth: '190px' }}>
                  {cardInfo.Initiator.Name}
                </StyledCardText>
              </StyledCardItem>
            </StyledCardItems>

            <StyledCardHeading>{cardInfo.Subject}</StyledCardHeading>
          </StyledHeading>
          <StyledCardItems>
            {cardInfo.Executor?.UID && <StyledCardText>{cardInfo.Executor.Name}</StyledCardText>}
            <StyledStatus theme={{ main: handleBackgroundStatus(cardInfo.Status) }}>
              {cardInfo.Status}
            </StyledStatus>
          </StyledCardItems>
        </StyledCardFlexContainer>
        <StyledCardFooter>
          <StyledCardText>Задача #{+cardInfo.Number}</StyledCardText>
          {/* <StyledCardText theme={{ textColor: handlePriorityColor(cardInfo.Priority) }}>
            {cardInfo.Priority}
          </StyledCardText> */}

          {/* <StyledCardItem>
            <StyledCardText>Отправлено</StyledCardText>
            <StyledCardImage
              src={cardInfo.TypeConnect ? taskTypeTelegamm : taskTypeMail}
              alt={'метод получения'}
            />
          </StyledCardItem> */}
        </StyledCardFooter>
        <StyledCardLine />
        <StyledCardFooter>
          <StyledCardItem>
            <StyledCardSpanLogo theme={{ logo: cardTimeLogo }} />
            <StyledCardText>
              {cardInfo.Date.split('-').join('.').split('T').join(' - ')}
            </StyledCardText>
          </StyledCardItem>
          <StyledCardAdditional>
            {/* {cardInfo.attachments && (
              <StyledCardItem>
                <StyledCardSpanLogo theme={{ logo: cardAttachmentLogo }} />
                <StyledCardText>{cardAttachmentLogo}</StyledCardText>
              </StyledCardItem>
            )} */}
            {!!cardInfo.Comments && (
              <StyledCardItem>
                <StyledCardSpanLogo theme={{ logo: cardCommLogo }} />
                <StyledCardText>{cardInfo.Comments}</StyledCardText>
              </StyledCardItem>
            )}
            {!!cardInfo.SubTasksTotal && (
              <StyledCardItem>
                <StyledCardSpanLogo theme={{ logo: cardFilesLogo }} />
                <StyledCardText>{cardInfo.SubTasksTotal}</StyledCardText>
              </StyledCardItem>
            )}
          </StyledCardAdditional>
        </StyledCardFooter>
      </StyledTask>
    </Link>
  );
};

export const NotificationCard = ({ cardInfo, notificationText, onClickHandler }: ITaskProps) => {
  return (
    <Link to={`/applications/${cardInfo.UID}`}>
      <StyledTask onClick={() => onClickHandler(cardInfo)}>
        {cardInfo.Executor?.UID && (
          <StyledTaskCornerSpan theme={{ main: handleBackgroundStatus(cardInfo.Status) }} />
        )}
        <StyledCardFlexContainer>
          <StyledHeading>
            <StyledCardItems>
              {/* <StyledCardItem>
                <StyledCardOrg
                  src={organizationAvatar}
                  alt={cardInfo.Initiator?.Name || cardInfo.Project?.Name}
                />
                <StyledCardText theme={{ maxWidth: '100px' }}>
                  {cardInfo.Organization?.Name || cardInfo.Service.Name || ServiceName}
                </StyledCardText>
              </StyledCardItem> */}
              <StyledCardItem>
                <StyledCardImage src={defaultAvatar} alt={cardInfo.Initiator.Name} />
                <StyledCardText theme={{ maxWidth: '190px' }}>
                  {cardInfo.Initiator.Name}
                </StyledCardText>
              </StyledCardItem>
            </StyledCardItems>

            <StyledCardHeading>{cardInfo.Subject}</StyledCardHeading>
          </StyledHeading>
          <StyledCardItems>
            {cardInfo.Executor?.UID && <StyledCardText>{cardInfo.Executor.Name}</StyledCardText>}
            <StyledStatus theme={{ main: handleBackgroundStatus(cardInfo.Status) }}>
              {cardInfo.Status}
            </StyledStatus>
          </StyledCardItems>
        </StyledCardFlexContainer>
        <StyledCardFooter>
          <StyledCardText>Задача #{+cardInfo.Number}</StyledCardText>
          {/* <StyledCardText theme={{ textColor: handlePriorityColor(cardInfo.Priority) }}>
            {cardInfo.Priority}
          </StyledCardText> */}

          {/* <StyledCardItem>
            <StyledCardText>Отправлено</StyledCardText>
            <StyledCardImage
              src={cardInfo.TypeConnect ? taskTypeTelegamm : taskTypeMail}
              alt={'метод получения'}
            />
          </StyledCardItem> */}
        </StyledCardFooter>
        <StyledCardLine />
        <StyledCardFooter>
          <StyledCardItem>
            <StyledCardSpanLogo theme={{ logo: cardTimeLogo }} />
            <StyledCardText>
              {cardInfo.Date.split('-').join('.').split('T').join(' - ')}
            </StyledCardText>
          </StyledCardItem>
          <StyledCardNotificationText>{notificationText}</StyledCardNotificationText>
        </StyledCardFooter>
      </StyledTask>
    </Link>
  );
};
