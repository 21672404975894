import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import styles from './NewTaskMobilePage.module.scss';
import { fetchDataPostSimple } from 'api';
import { NewTaskDescType } from '@shared/types';
import ProtectedRoute from '@shared/HOC/ProtectedRoute';
// import { ModalType } from '@shared/enums';
import { NewTaskDeskDefault } from '@shared/constants';
import { refreshTasks } from '@redux/slices/tasksSlice';
import { TasksDispatch } from '@redux/store';

import Header from '@components/Header/Header';
import { Footer } from '@components/Footer/Footer';
import { SimpleBigButton } from '@components/UI/Button';
import Preloader from '@components/UI/Preloader/Preloader';
// import ModalInfo from '@components/Modal/ModalInfo/ModalInfo';
// import { AuthContext } from '@contexts/CurrUserContext';
import NewTask from '@components/Tasks/NewTask';

const NewTaskMobilePage = () => {
  const [newTaskDesc, setNewTaskDesc] = useState<NewTaskDescType>(NewTaskDeskDefault);
  // const [isAppListOpen, setAppListOpen] = useState(false);
  // const [taskFiles, setTaskFiles] = useState<{ file: string; name: string; link: string }[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [openResultModal, setResultModal] = useState(false);
  // const [typeResultModal, setTypeResultModal] = useState<ModalType>('new' as ModalType);
  // const [newAppNumber, setNewAppNumber] = useState('');
  const dispatch = useDispatch<TasksDispatch>();

  // useEffect(() => {
  //   setNewApp({
  //     ...newApp,
  //     Executor: { UID: userInfo.UID, Name: userInfo.Name || userInfo.FullName },
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [userInfo.UID]);

  const handleSubmitForm = () => {
    setIsLoading(true);
    const reqBody = {
      Subject: newTaskDesc.Subject,
      Initiator: newTaskDesc.Initiator.UID,
      Project: newTaskDesc.Project.UID,
      Note: newTaskDesc.Note,
      Status: newTaskDesc.Status,
    };
    !!newTaskDesc.Executor.UID && Object.assign(reqBody, { Executor: newTaskDesc.Executor.UID });
    !!newTaskDesc.Parent?.UID && Object.assign(reqBody, { Parent: newTaskDesc.Parent.UID });
    !!newTaskDesc.DeadLine && Object.assign(reqBody, { DeadLine: newTaskDesc.DeadLine });
    !!newTaskDesc.CheckList?.length && Object.assign(reqBody, { CheckList: newTaskDesc.CheckList });
    fetchDataPostSimple('tasks/add', reqBody)
      .then((res) => {
        // setResultModal(true);
        // setTypeResultModal(ModalType['newApp']);
        // setNewAppNumber(res.Number);
        setNewTaskDesc(NewTaskDeskDefault);
        dispatch(refreshTasks());
      })
      .catch((err) => {
        console.error(err);
        // setResultModal(true);
        // setTypeResultModal(ModalType['err']);
      })
      .finally(() => setIsLoading(false));
  };

  // const handleAddNewFile = (v: { file: string; name: string; link: string }) => {
  //   setTaskFiles([...taskFiles, v]);
  // };

  // const handleDeleteFile = (name: string) => {
  //   setTaskFiles(taskFiles.filter((item) => item.name !== name));
  // };

  return (
    <main className={styles.page}>
      <Header pageName={'Создать заявку'} />
      <div className={styles.info}>
        <NewTask
          NewTaskDesk={newTaskDesc}
          setNewTaskDesc={setNewTaskDesc}
          // handleAddNewFile={handleAddNewFile}
          // handleDeleteFile={handleDeleteFile}
          // fileItems={taskFiles}
        />
        <div className={styles.loading}>
          {isLoading ? (
            <Preloader />
          ) : (
            <SimpleBigButton
              text={'Создать заявку'}
              handleClick={() => handleSubmitForm()}
              styling={styles.submit}
              disabled={
                !newTaskDesc.Initiator.UID ||
                !newTaskDesc.Subject ||
                !newTaskDesc.Project.UID ||
                isLoading
              }
            />
          )}
        </div>
      </div>
      <Footer />
      {/* <ModalInfo
        isOpen={openResultModal}
        closeModal={() => setResultModal(false)}
        type={typeResultModal}
        textContent={newAppNumber}
      /> */}
    </main>
  );
};

export default ProtectedRoute(NewTaskMobilePage);
