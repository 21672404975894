import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import tasksSlice from './slices/tasksSlice';
import filterSlice from './slices/filterSlices';
import notificationSlice from './slices/notificationSlice';

export const store = configureStore({
  reducer: {
    tasks: tasksSlice,
    filter: filterSlice,
    notifications: notificationSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type TasksDispatch = typeof store.dispatch;
export type TasksThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
