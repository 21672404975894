import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import { useSelector } from 'react-redux';

import userAvatar from '@assets/defpage/avatar.png';
import styles from './DefComp.module.scss';
import { AuthContext } from '@contexts/CurrUserContext';

const DefComp = () => {
  const navigate = useNavigate();
  const { userInfo } = useContext(AuthContext);

  return (
    <div className={styles.component}>
      <div className={styles.heading}>
        <Link to="/profile" className={styles.userInfo}>
          <img
            className={styles.userAvatar}
            src={userInfo.Avatar || userAvatar}
            alt="user avatar"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = userAvatar;
            }}
          />

          <h2 className={styles.userName}>{userInfo.Name || userInfo.FullName}</h2>
        </Link>
        <p className={styles.text}>
          Сегодня {new Date().toISOString().split('T')[0].split('-').reverse().join('.')}
        </p>
      </div>
      <div className={styles.projects}>
        {/* <RoundedReport options={appsArr} /> */}
        <div className={styles.items}>
          <div>
            <h3 className={styles.title}>Часто задаваемые вопросы</h3>
          </div>
          <button className={styles.button} onClick={() => navigate('/knowledge')}>
            Изучить
          </button>
        </div>
        {/* <img className={styles.defImage} src={secondImage} alt="some logo" /> */}
      </div>
      {/* <ModalPWA
        isOpen={isPWAModalOpen}
        closeModal={() => setPWAModalOpen(false)}
        handleConfirm={handleInstallPWA}
      /> */}
    </div>
  );
};

export default DefComp;
