import { ColumnNameEnum, TaskStatusEnum } from '@shared/enums';
import {
  IFilter,
  NewCheckItemType,
  CheckListItemType,
  TaskListType,
  TaskCommentType,
} from '@shared/types';
import { fetchDataPostSimple } from 'api';
import { Base64 } from 'js-base64';

export const handleEncodeToBase64 = (v: string) => 'Basic ' + Base64.encode(v);

export const handleDecodeFromBase64 = (v: string) => Base64.decode(v);

const dateItem = (v: string) => (v.length > 1 ? v : '0' + v);

export const handleDateFormat = (v: Date) => {
  const dateMonth = v.getMonth().toLocaleString();
  const dateDay = v.getDay().toLocaleString();
  const dateHour = v.getHours().toLocaleString();
  const dateMin = v.getMinutes().toLocaleString();
  const dateSec = v.getSeconds().toLocaleString();
  return `${v.getFullYear()}-${dateItem(dateMonth)}-${dateItem(dateDay)}T${dateItem(
    dateHour
  )}:${dateItem(dateMin)}:${dateItem(dateSec)}`;
};

export const getBase64 = (file: any) => {
  return new Promise((resolve) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
  });
};

export const handleBackgroundStatus = (v: string) => {
  switch (v) {
    case 'Черновик':
      return '#8391A5';
    case 'Назначена':
      return '#FABB18';
    case 'Ожидание':
      return '#FABB18';
    case 'В работе':
      return '#378DFE';
    case 'Отложена':
      return '#FABB18';
    case 'Подтверждение':
      return '#91BD13';
    case 'Выполнена':
      return '#91BD13';
    default:
      return '#FC0800';
  }
};

export const handleFilterTasks = (v: IFilter, tasks: TaskListType[]) => {
  const { projectFilter, initiatorFilter, executorFilter, dateRangeFilter, statusFilter } = v;
  return tasks
    .filter((item) => (!!projectFilter.Name ? item.Project?.UID === projectFilter.UID : item))
    .filter((item) => (!!initiatorFilter.Name ? item.Initiator.UID === initiatorFilter.UID : item))
    .filter((item) => (!!executorFilter.Name ? item.Executor?.UID === executorFilter.UID : item))
    .filter((item) => (statusFilter.length ? statusFilter.includes(item.Status) : item))
    .filter((item) =>
      dateRangeFilter[0]
        ? new Date(item.Date)[Symbol.toPrimitive]('number') >= dateRangeFilter[0]
        : item
    )
    .filter((item) =>
      dateRangeFilter[1]
        ? new Date(item.Date)[Symbol.toPrimitive]('number') <= dateRangeFilter[1]
        : item
    );
};

export const handleFilterByStatusAndDataRange = (
  dateRangeFilter: [number, number],
  statusFilter: TaskStatusEnum[],
  tasks: TaskListType[]
) => {
  return tasks
    .filter((item) => (statusFilter.length ? statusFilter.includes(item.Status) : item))
    .filter((item) =>
      dateRangeFilter[0]
        ? new Date(item.Date)[Symbol.toPrimitive]('number') >= dateRangeFilter[0]
        : item
    )
    .filter((item) =>
      dateRangeFilter[1]
        ? new Date(item.Date)[Symbol.toPrimitive]('number') <= dateRangeFilter[1]
        : item
    );
};

export const handleFilterByColumn = (v: ColumnNameEnum) => {};

// interface IChangeCheckList {
//   newArr: NewCheckItemType[] | CheckListItemType[],
// }

export const handleChangeCheckList = (
  checkListArr: NewCheckItemType[] | CheckListItemType[],
  mode: 'onClick' | 'onChange' | 'up' | 'down' | 'delete',
  v: string,
  index: number
) => {
  let newArr: NewCheckItemType[] | CheckListItemType[] = [];
  switch (mode) {
    case 'onClick':
      newArr = checkListArr.map((item, i) => {
        return i === index ? { ...item, Checked: !item.Checked } : item;
      });
      break;
    case 'onChange':
      newArr = checkListArr.map((item, i) => {
        return i === index ? { ...item, CheckName: v } : item;
      });
      break;
    case 'up':
      newArr = [
        ...checkListArr.slice(0, index - 1),
        checkListArr[index],
        checkListArr[index - 1],
        ...checkListArr.slice(index + 1, checkListArr.length),
      ];
      break;
    case 'down':
      newArr = [
        ...checkListArr.slice(0, index),
        checkListArr[index + 1],
        checkListArr[index],
        ...checkListArr.slice(index + 2, checkListArr.length),
      ];
      break;
    default:
      newArr = checkListArr.filter((item, i) => i !== index);
      break;
  }
  return newArr;
};

export const handleNewTextComment = (
  newComment: string,
  setNewComment: (value: React.SetStateAction<string>) => void,
  setIsLoading: (value: React.SetStateAction<boolean>) => void,
  appUID: string,
  setCommArr: (value: React.SetStateAction<TaskCommentType[]>) => void,
  commArr: TaskCommentType[],
  userUID: string,
  userName: string
) => {
  if (/^\s*$/.test(newComment)) {
    return setNewComment('');
  }
  setIsLoading(true);
  fetchDataPostSimple('tasks/addcomment', { UID: appUID, Note: newComment.trim() })
    .then(() => {
      setCommArr([
        ...commArr,
        {
          SentFrom: {
            UID: userUID,
            Name: userName,
          },
          CommentDateTime: handleDateFormat(new Date()),
          Note: newComment,
          Readed: true,
        },
      ]);
      return setNewComment('');
    })
    .catch((err) => console.log(err))
    .finally(() => setIsLoading(false));
};
